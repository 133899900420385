import { apolloClient } from '@/vue-apollo'
import gql from 'graphql-tag'

export default {
    state: {
        serviceCostAnalytics: {}
    },

    mutations: {
        addServiceCostsAnalytics(state, data) {
            state.serviceCostAnalytics = data
        },
    },

    getters: {
        getServiceCostAnalytics(state) {
            return state.serviceCostAnalytics
        },
    },
    actions: {
        async loadServiceCostsAnalytics(context) {
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: gql`query serviceCostAnalytics{
                    serviceCostAnalytics{
                      serviceCostGroups{
                        groupName
                        groupValue
                      }
                      mostCostlyServices{
                        serviceName
                        serviceInstituion
                        serviceGroup
                        serviceCost
                      }
                      mostCostlyServicesPerInstitution{
                        serviceName
                        serviceInstituion
                        serviceGroup
                        serviceCost
                      }
                      serviceCostPerGroups{
                        groupName
                        serviceCostGroups{
                          groupName
                          groupValue
                        }
                      }
                    }
                  }`
            }).then((response) => {
                context.commit('addServiceCostsAnalytics', response.data.serviceCostAnalytics)
            })
        },
    }
}
