import { apolloClient } from '@/vue-apollo'
import gql from 'graphql-tag'

export default {
    state: {
        serviceDeliveryTimeAnalytics: {}
    },

    mutations: {
        addServiceDeliveryTimesAnalytics(state, data) {
            state.serviceDeliveryTimeAnalytics = data
        },
    },

    getters: {
        getServiceDeliveryTimeAnalytics(state) {
            return state.serviceDeliveryTimeAnalytics
        },
    },
    actions: {
        async loadServiceDeliveryTimesAnalytics(context) {
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: gql`query serviceDeliveryTimeAnalytics{
                    serviceDeliveryTimeAnalytics{
                      mostTimeConsumaleServices{
                        service
                        duration
                      }
                      institutionServicesAverageTime{
                        institutionName
                        institutionSector
                        totalServices
                        timeAverage
                      }
                      sectorialServicesAverageTime{
                        sectorName
                        timeAverage
                      }
                    }
                  }`
            }).then((response) => {
                context.commit('addServiceDeliveryTimesAnalytics', response.data.serviceDeliveryTimeAnalytics)
            })
        },
    }
}
