<template>
  <v-container fluid class="my-5" app>
   <v-breadcrumbs :items="items">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
          {{ item.text.toUpperCase() }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
<v-row>
<v-spacer></v-spacer>
      <v-chip
        class="ma-2 mb-4"
        @click="OpenNewCategoryForm = !OpenNewCategoryForm"
        v-bind="attrs"
        v-on="on"
        color="primary"
        label
        style="font-weight: bold"
        outlined
        text-color="primary"
      >
        <v-icon left> mdi-view-dashboard-edit-outline </v-icon>
        New Category
      </v-chip>
</v-row>

<v-data-table
    :headers="headers"
    :items="categories"
    :items-per-page="5"
    class="elavation-1 mt-6"
  >
      <template v-slot:[`item.actions`]="{ item }">
                <v-btn icon v-bind="attrs" v-on="on">

      <v-icon
        small
     
      >
        mdi-delete
      </v-icon>
                </v-btn>

    </template>
  </v-data-table>

 <AddNewCategory
        :OpenNewCategoryForm="OpenNewCategoryForm"
        @close="
         OpenNewCategoryForm = !OpenNewCategoryForm;

        "/>

        <v-dialog
        v-model="popupDialog"
        max-width="600px"
        persistent
        :key="popupDialog"
        >
         <v-card>
        <v-card-title>
          <v-icon>mdi-account-plus-outline</v-icon>
          <span @click="OpenNewCategoryForm"> Add Service </span>
        </v-card-title>
      </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import AddNewCategory from "./AddNewCategory.vue"
export default {
  components:{
      AddNewCategory
  },
  data: () => ({
    OpenNewCategoryForm:false,
     items: [
       {
        text: "Dashboard",
        disabled: false,
        href: "/private/dashboard",
      },
      {
        text: "List of Categories",
        disabled: true,
        href: "",
      },
    ],
     headers: [
      {
        text: "Category Name",
        align: "start",
        sortable: false,
        value: "categoryname",
      },
      {
        text: "Category Description",
        align: "start",
        sortable: false,
        value: "categorydescription",
      },

      { text: "Actions", value: "actions" },
    ],

    categories:[
      {
        categoryname:"Category 1 ",
        categorydescription:"its the first category......."
      },  
      {
        categoryname:"Category 2 ",
        categorydescription:"its the second category......."
      },
    ],

    methods: {
      deleteItem(item) {
        t
      }
    }
  }),



}
</script>

<style>
v-btn:hover {
  background-color: rgb(189, 213, 235);
  color: rgb(24, 28, 32);
}
thead .text-start {
  color: #000922 !important;
  font-size: medium !important;
  background-color: rgb(247, 243, 231);
}

</style>