import { apolloClient } from '@/vue-apollo'
import gql from 'graphql-tag'

export default {
    state: {
        serviceAffordabilityAnalytics: {}
    },

    mutations: {
        addServiceAffordabilityAnalytics(state, data) {
            state.serviceAffordabilityAnalytics = data
        },
    },

    getters: {
        getServiceAffordabilityAnalytics(state) {
            return state.serviceAffordabilityAnalytics
        },
    },

    actions: {
        async loadServiceAffordabilityAnalytics(context) {
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: gql`query serviceAffordabilityAnalytics{
                  serviceAffordabilityAnalytics{
                    serviceAffordabilityByTypes{
                      numberOfG2c
                      numberOfG2b
                      numberOfG2g
                      numberOfG2e
                    }
                    serviceAffordabilityPerInstitutions{
                      serviceName
                      serviceInstitution
                      serviceGroup
                      serviceCost
                      serviceTime
                    }
                  }
                }`
            }).then((response) => {
                context.commit('addServiceAffordabilityAnalytics', response.data.serviceAffordabilityAnalytics)
            })
        },
    }
}
