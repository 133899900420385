<template >
  <v-app id="app" v-bind:style="{ backgroundColor: color }">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
const regeneratorRuntime = require("regenerator-runtime");
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style>
#app {
  background-color: rgba(161, 173, 230, 0.123);
}
</style>