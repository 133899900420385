<template>
  <v-container fluid class="my-5" app>
    <v-breadcrumbs :items="items">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
          {{ item.text.toUpperCase() }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <v-row
      >
      <v-col cols="7">
        <v-card>
          <v-card-text>
            <p class="text-h6 text--primary">Top 5 Most Used Requirements</p>
          </v-card-text>
          <most-used :data="mostUsage"/>
        </v-card>
      </v-col>
      <v-col cols="5">
        <div class="text-overline mb-4">3 Services with many requirements</div>
        <v-card
          v-for="service in RequirementsAnalytics.serviceRequirements"
          :key="service"
          class="mx-auto mb-3"
          max-width="100%"
          outlined
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-1">
                {{ service.serviceName }}
              </div>
              <v-list-item-title class="text-h6 mb-1">
                Requirements: {{ service.serviceRequirements }}
              </v-list-item-title>
              <v-list-item-subtitle
                >Provided by:
                <strong>
                  {{ service.serviceProvider }}</strong
                ></v-list-item-subtitle
              >
            </v-list-item-content>
            <v-list-item-action>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon color="grey lighten-1"> mdi-playlist-star </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="requirement in service.serviceRequirementsList"
                    :key="requirement"
                  >
                    <v-list-item-title>
                      {{ requirement.requirementName }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="start">
      <v-col cols="12">
        <div class="text-overline">
          Institutions with many services requirements
        </div>
        <v-card class="mx-auto ml-1 mt-3" max-width="100%" outlined>
          <bar-chart :data="series" />
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div justify="start" class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
          <v-text-field
            :value="itemsPerPage"
            label="Items per page"
            type="number"
            min="-1"
            max="15"
            @input="itemsPerPage = parseInt($event, 10)"
          ></v-text-field>
          <v-btn outlined small color="primary" class="mb-2 ml-2">
            <v-icon> mdi-download-outline </v-icon> csv
          </v-btn>
          <v-btn outlined small color="success" class="mb-2 ml-2">
            <v-icon> mdi-download-outline </v-icon> excel
          </v-btn>
        </div>
        <v-data-table
          :headers="headers"
          :items="RequirementsAnalytics.requirementsUsage"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          class="elevation-1"
        ></v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MostUsed from "./MostUsed.vue";
import BarChart from "./BarChart.vue";
import { mapGetters } from "vuex";

export default {
  components: { MostUsed, BarChart },
  data: () => ({
    items: [
      {
        text: "Statistics and Reports",
        disabled: false,
        href: "/private/statistics-reports",
      },
      {
        text: "Service Requirment Analysis",
        disabled: true,
        href: "",
      },
    ],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    headers: [
      {
        text: "Requirements",
        align: "start",
        sortable: false,
        value: "requirementName",
      },
      { text: "No. Services", value: "requirementUsage" },
    ],
  }),
  computed: {
    ...mapGetters({
      RequirementsAnalytics: "getRequirementsAnalytics",
    }),
    series() {
      var series_data = [];
      var services_data = [];
      var requirements_data = [];
      var labels = [];
      this.RequirementsAnalytics.institutionsRequirements.forEach((institution) => {
            labels.push(institution.institutionName)
            services_data.push(institution.numberOfRequirements)
            requirements_data.push(institution.numberOfServices)
      });

      series_data.push({
        name: "Services",
        type: "bar",
        data: services_data,
      });

      series_data.push({
        name: "Requirements",
        type: "bar",
        data: requirements_data,
      });

      var data={
        labels:labels,
        series:series_data,
      }

      return data;
    },

    mostUsage(){
      var data=[]
      this.RequirementsAnalytics.topFiveUsage.forEach((usage) => {
            data.push({ value:usage.requirementUsage, name: usage.requirementName })
      });

      return data
    }
  },

  async mounted() {
    await this.$store.dispatch("loadRequirementsAnalytics");
  },
};
</script>

<style>
</style>