<template>
<sequential-entrance>
        
    <section id="hero">
      <v-row>
        <v-col cols="3" class="pl-8">
          <div class="container-custom pa-8">
            <h3 class="font-weight-bold display-0">
            Makundi ya Huduma
            </h3>

            <v-checkbox
              v-for="category in serviceCategories"
              :key="category"
              :label="category"
              hide-details
              @change="selectedServices.push(category)"
            ></v-checkbox>
          </div>
          
        </v-col>
        <v-col cols="9">
            <v-parallax class="mt-4 container-custom" dark  src="@/assets/home.png" >
              <v-row align="center" justify="center">
                <v-col cols="10" class="mt-6">
                  <v-row align="center" justify="center">
                    <!-- <v-col cols="12" md="6" xl="8" class="pt-10 ">
                      <v-hover>
                        <v-btn
                          rounded
                          outlined
                          large
                          dark
                          @click="$vuetify.goTo('#features')"
                          class="mt-7 info light"
                          style="color: lightblue"
                        >
                          Tazama Huduma
                          <v-icon style="color: lightblue" class="ml-2">mdi-arrow-down</v-icon>
                        </v-btn>
                      </v-hover>
                    </v-col> -->
                    <v-col cols="12" md="6" xl="4" class="hidden-sm-and-down"> </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <div class="svg-border-waves text-white">
                <v-img src="@/assets/img/borderWaves.svg" />
              </div>
            </v-parallax>
          <div class="container-custom pa-8">
            <v-row>
              <v-col cols="10" sm="10" md="10">
                <v-text-field
                  full-width
                  clearable
                  outlined
                  label="Tafuta Huduma"
                  placeholder="Tafuta Huduma"
                  prepend-inner-icon="mdi-magnify"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2" sm="2" md="2">
                <div class="text-center">
                  <v-btn  x-large block color="#e6bc22" dark style="border-radius:10px"> Tafuta </v-btn>
                </div>
              </v-col>
            </v-row>

              <span v-if="selectedServices.length >0">23 filtered results found</span> <br><br>
            <v-row>
              <span v-if="selectedServices.length >0"><strong>Filtering by:</strong></span>
              <v-chip
                v-for="service in selectedServices"
                :key="service+'1'"
                class="ma-2"
                close
                color="#5596e6"
                label
                outlined
              >
                {{service}}
              </v-chip>

              <v-col
                cols="12"
                sm="12"
                md="12"
                v-for="(service, i) in listOfServices"
                :key="i"
              >
                <v-card  link to="/ServiceDetails" class="mx-auto rounded-xl pa-4" max-width="100%" tile elevation="2">
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text-overline mb-4">Taarifa za Huduma</div>
                      <v-list-item-title class="text-h5 mb-1">
                        {{service.name}}
                      </v-list-item-title>
                      <v-list-item-subtitle >{{service.agency}}</v-list-item-subtitle >
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
                        <v-row justify="center">
              <v-col cols="8">
                <v-container class="max-width">
                  <v-pagination
                    v-model="page"
                    class="my-4"
                    :length="15"
                    circle
                    color="orange"
                    :total-visible="7"
                  ></v-pagination>
                </v-container>
              </v-col>
            </v-row>
            </v-row>
          </div>
        </v-col>
      </v-row>
      
      <v-container class="mt-2">
        <v-row id="features">
          
          
        </v-row>

      </v-container>
      <v-dialog v-model="dialog" max-width="640px">
        <v-card>
          <youtube
            :video-id="videoId"
            @ready="ready"
            @playing="playing"
          ></youtube>
        </v-card>
      </v-dialog>
      <div class="svg-border-waves">
        <img src="~@/assets/img/wave2.svg" />
      </div>
    </section>
</sequential-entrance>
</template>



<script>
export default {
  data() {
    return {
      page: 1,
      selectedServices:[],
      dialog: false,
      videoId: "i8IvvHJssWE",
      listOfServices:[
          {
            name:"Utoaji Hataza	Haki-Miliki ya Ubunifu",
            agency:'COSOTA'
          },
          
          {
            name:"Usajili wa alama za Biashara na Huduma",
            agency:'BRELA'
          },
          
          {
            name:"Usajili wa majina ya biashara",
            agency:'BRELA'
          },
          
          {
            name:"Kutoa tarifa za kiutendaji",
            agency:'Mahakama ya Tanzania'
          },
          
          {
            name:"Kutoa huduma za kiutawala na kiutumishi",
            agency:'Mahakama ya Tanzania'
          },
          
          {
            name:"Kutoa tarifa za ukaguzi",
            agency:'Mahakama ya Tanzania'
          },
          
          {
            name:"Usaidizi wa kitaalamu katika huduma za TEHAMA	-	Mawasiliano na Tehama",
            agency:'eGA'
          },
          
          {
            name:"Kutoa zabuni mbalimbali",
            agency:'PPAA'
          },
          
          {
            name:"Utoaji Leseni za Biashara Kundi A	",
            agency:'BRELA'
          },
          
      ],

      serviceCategories: [
        "Uchukuzi, Ujenzi na Usafirishaji",
        "Nishati na Madini",
        "Nyumba na huduma za mitaa",
        "Ajira, Kazi na pensheni",
        "Elimu na mafunzo",
        "Watu wenye Ulemavu",
        "Uhalifu, haki na sheria",
        "Uraia, viza na uhamiaji",
        "Maji na Umwagiliaji",
        "Fedha na ushuru (Kodi)",
        "Kilimo na Uvuvi",
        "Sanaa na Michezo",
        "Viwanda na Biashara",
        "Ulinzi na Usalama",
        "Mawasiliano na Tehama",
        "Nyaraka na takwimu (Data za Wazi)",
        "Ardhi na Mazingira",
        "Utawala na Uongozi",
        "Afya",
        "Kuzaliwa, Vifo na Ndoa",
        "Maliasili na Utalii",
      ],
    };
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.pause();
      }
    },
  },
  methods: {
    ready(event) {
      this.player = event.target;
    },
    playing(event) {
      console.log(event);
    },
    change() {
      this.videoId = "another video id";
    },
    stop() {
      this.player.stopVideo();
    },
    pause() {
      this.player.pauseVideo();
    },
  },
};
</script>

<style lang="scss">
.container-custom{
  background: #fff;
  border-radius: 15px;
  margin-top:15px;
  
}
.circle {
  stroke: white;
  stroke-dasharray: 650;
  stroke-dashoffset: 650;
  -webkit-transition: all 0.5s ease-in-out;
  opacity: 0.3;
}

.playBut {
  /*  border: 1px solid red;*/
  display: inline-block;
  -webkit-transition: all 0.5s ease;

  .triangle {
    -webkit-transition: all 0.7s ease-in-out;
    stroke-dasharray: 240;
    stroke-dashoffset: 480;
    stroke: white;
    transform: translateY(0);
  }

  &:hover {
    .triangle {
      stroke-dashoffset: 0;
      opacity: 1;
      stroke: white;
      animation: nudge 0.7s ease-in-out;

      @keyframes nudge {
        0% {
          transform: translateX(0);
        }
        30% {
          transform: translateX(-5px);
        }
        50% {
          transform: translateX(5px);
        }
        70% {
          transform: translateX(-2px);
        }
        100% {
          transform: translateX(0);
        }
      }
    }

    .circle {
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }
}
</style>

<style>
.btn-play {
  transition: 0.2s;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

#hero {
  z-index: 0;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}

.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.1);
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}
</style>

<style>
section {
  position: relative;
}
</style>











