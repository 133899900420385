import { apolloClient } from '@/vue-apollo'
import gql from 'graphql-tag'
import swal from 'sweetalert2'
import router from '../../router/index.js'

export default {
    state: {
        token: localStorage.getItem('apollo-token') || null,
        user: {},
        permissions: []
    },

    mutations: {
        cleanUserDetails(state) {
            state.user = {}
        },
        addUserDetails(state, data) {
            state.user = data.user
            state.token = data.token
        },
        addUserPermissions(state, permissions) {
            state.permissions = permissions
        }
    },

    getters: {
        getUserRoles(state) {
            return state.user
        },
        getUserPermissions: state => state.permissions,
        user: state => state.user
    },

    actions: {
        async userAuthentication(context, userDetails) {
            context.commit('cleanUserDetails')
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: gql`mutation customUserTokenAuthentication($username:String!,$password:String!){
                    customUserTokenAuthentication(username:$username,password:$password){
                      token
                      success
                      user{
                        username
                        firstName
                        lastName
                      }
                      permissions{
                        permisionUniqueId
                        permisionCode
                        permisionName
                      }
                    }
                  }`,
                variables: {
                    username: userDetails.username,
                    password: userDetails.password,
                }
            }).then((response) => {
                console.log(response);
                if (response.data.customUserTokenAuthentication.success) {

                    var logData = response.data.customUserTokenAuthentication

                    const token = JSON.stringify(logData.token)

                    localStorage.setItem('apollo-token', token)

                    var permision_list = []
                    logData.permissions.forEach(permision => {
                        permision_list.push(
                            permision.permisionCode
                        )
                    });

                    context.commit('addUserPermissions', permision_list)
                    context.commit('addUserDetails', logData)

                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: 'User login successfully',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                    router.push('/private/dashboard')
                } else {
                    swal.fire({
                        toast: true,
                        icon: 'warning',
                        title: 'Fail to login kindly contact your administrator',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).catch((error) => {
                swal.fire({
                    toast: true,
                    icon: 'warning',
                    title: 'Fail to login kindly contact your administrator',
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            })
        },
    }
}
