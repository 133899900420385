import { apolloClient } from '@/vue-apollo'
import gql from 'graphql-tag'

export default {
    state: {
        serviceTypeAnalytics: {}
    },

    mutations: {
        addServiceTypesAnalytics(state, data) {
            state.serviceTypeAnalytics = data
        },
    },

  getters: {
      getServiceTypeAnalytics(state) {
          return state.serviceTypeAnalytics
      },
    },

  actions: {
      async loadServiceTypesAnalytics(context) {
          await apolloClient.query({
              fetchPolicy: 'no-cache',
              query: gql`query serviceTypesAnalytics{
                serviceTypesAnalytics{
                  servicePerCategory{
                    name
                    value
                  }
                  mostG2cInstitution{
                    institutionName
                    numberOfServices
                    serviceList{
                      serviceName
                    }
                  }
                  institutionServicesType{
                    institutionName
                    totalServices
                    numberOfG2c
                    numberOfG2g
                    numberOfG2e
                    numberOfG2b
                  }
                }
              }`
          }).then((response) => {
              context.commit('addServiceTypesAnalytics', response.data.serviceTypesAnalytics)
          })
      },
  }
}
