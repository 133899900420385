<template>
  <v-row justify="center">
    <v-dialog v-model="OpenNewRequirmentForm" persistent max-width="80%">
      <v-card>
        <v-card-title>
          <span class="text-h6"
            ><v-icon>mdi-account-plus-outline</v-icon> Add New Requirment
            </span
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                  <v-select
                v-model="RequirmentGroup"
                  :items="listofGroup"

                  chips
                  clearable
                  item-text="name"
                  item-value="id"
                  label="Select Requirment Group*"
                  required
                  prepend-icon="mdi-view-dashboard-edit-outline"
                >
                </v-select>
            
                <v-text-field
                  v-model="RequiredItems"
                  :counter="10"
                  label="Required Items *"
                  required
                  prepend-icon="mdi-text-recognition"
                >
                </v-text-field>

            

              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            small
            outlined
            class="white--text"
            @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
            Close
          </v-btn>
          <v-btn
            color="primary"
            small
            outlined
            class="white--text"
            @click="newRequirmentRegistration"
          >
            <v-icon>mdi-check-all</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
export default {
  props: ["OpenNewRequirmentForm"],
  components: {},
  data: () => ({
    RequirmentGroup:"",
    RequirmentName: "",
    RequiredItems: "",

     listofGroup:[
    "Requirment Group 1",
    "Requirment Group 2",
    "Requirment Group 3",
    "Requirment Group 4",
    "Requirment Group 5",
    "Requirment Group 6",
    "Requirment Group 7",
  ]
 
  }),


};
</script>