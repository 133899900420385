import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import ECharts from 'vue-echarts'
import VueSweetalert2 from 'vue-sweetalert2';
import SequentialEntrance from 'vue-sequential-entrance'
import VueApexCharts from 'vue-apexcharts'
import "regenerator-runtime";
import 'vue-sequential-entrance/vue-sequential-entrance.css'
import 'sweetalert2/dist/sweetalert2.min.css';
import { use } from 'echarts/core'
import { createProvider } from './vue-apollo'
import {CanvasRenderer} from 'echarts/renderers'
import {BarChart} from 'echarts/charts'
import {GridComponent,TooltipComponent} from 'echarts/components'
use([CanvasRenderer, BarChart, GridComponent, TooltipComponent]);

Vue.use(VueSweetalert2);
Vue.use(SequentialEntrance);
Vue.component('v-chart', ECharts)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.config.productionTip = false








Vue.mixin({
  computed: {
      hasAccess: ()=> (permision)=> {
      const permissions = store.getters.getUserPermissions
        return  permissions.includes(permision)
      },
  },
})








new Vue({
  router,
  store,
  vuetify,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')
