<template>
  <v-container fluid class="my-5" app>
   <v-breadcrumbs :items="items">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
          {{ item.text.toUpperCase() }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
<v-row>
<v-spacer></v-spacer>
      <v-chip
        class="ma-2 mb-7"
        @click="OpenNewServiceForm = !OpenNewServiceForm"
        v-bind="attrs"
        v-on="on"
        color="primary"
        label
        style="font-weight: bold"
        outlined
        text-color="primary"
      >
        <v-icon left> mdi-hand-coin </v-icon>
        New Service
      </v-chip>
</v-row>

     <v-card >
          <v-expansion-panels>

          <v-expansion-panel v-for="(item, i) in serviceheaders" :key="i">
            <v-expansion-panel-header>
              {{ item.name }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <template class="text-centre"> 

                <v-card-subtitle>
               <div class="text-h2  col-12">
                <v-simple-table
                fixed-header
              >
                <template v-slot:default>
                  <thead >
                    <tr>
                      <th class="">
                        SERVICE NAME
                      </th>
                       <th class="text-left">
                        DESCRIPTION
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in servicetable"
                      :key="item.description"
                    >
                      <td>{{ item.servicename }}</td>
                      <td>{{ item.description}}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table></div>
                </v-card-subtitle>
                <v-divider> </v-divider>
                
                <v-divider></v-divider>

              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>

          </v-expansion-panels>
        </v-card>
         <AddNewService
        :OpenNewServiceForm="OpenNewServiceForm"
        @close="
         OpenNewServiceForm = !OpenNewServiceForm;

        "/>

        <v-dialog
        v-model="popupDialog"
        max-width="600px"
        persistent
        :key="popupDialog"
        >
         <v-card>
        <v-card-title>
          <v-icon>mdi-account-plus-outline</v-icon>
          <span @click="OpenNewServiceForm"> Add Service </span>
        </v-card-title>
      </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import AddNewService from "./AddNewService.vue";
export default {
  components: {
    AddNewService,
  },
  data: () => ({
    OpenNewServiceForm:false,
    selectedrequirments:[],
    items: [
       {
        text: "Dashboard",
        disabled: false,
        href: "/private/dashboard",
      },
      {
        text: "List of Service",
        disabled: true,
        href: "",
      },
    ],
    serviceheaders:[
      { name: "Huduma za Kijamii" },
      { name: "Huduma za Kibiashara" },
    ],

    servicetable:[
      {
        servicename:'TIN NUMBER',
        description:'Display briefly about the service and providers of tha specific service'
      },
       {
        servicename:'NIDA NUMBER',
        description:'Display briefly about the service and providers of tha specific service'
      },
      
       {
        servicename:'PASSPORT',
        description:'Display briefly about the service and providers of tha specific service'
      },
      
       {
        servicename:'VISA ALLOWANCE',
        description:'Display briefly about the service and providers of tha specific service'
      },
      
       {
        servicename:'RECRUITMENTS',
        description:'Display briefly about the service and providers of tha specific service'
      },
      
    ],

  requirmentschecks: [
    
   {name:"Mpango mkakati wa Taasisi"},
     { name:  "Majalada husika"},
       {name: "Nishati na Madini"},  
        {name:"Kumbukumbu na nyaraka za Taasisi"},
     { name:  "Kujaza fomu ya usajili wa kikao"},
       {name: "Andiko la mradi"},

      ],
  })

}
</script>

<style>

</style>