<template>
  <v-row justify="center">
    <v-dialog v-model="OpenNewServiceForm" persistent max-width="80%">
      <v-card>
        <v-card-title>
          <span class="text-h6"
            ><v-icon>mdi-hand-coin</v-icon> Add New Service
            </span
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-select
                v-model="SelectCategoryName"
                  :items="listofcategories"

                  chips
                  clearable
                  item-text="name"
                  item-value="id"
                  label="Select Category*"
                  required
                  prepend-icon="mdi-view-dashboard-edit-outline"
                >
                </v-select>
                 <v-text-field
                  v-model="RequirmentName"
                  :counter="10"
                  label=" Service Name *"
                  required
                  prepend-icon="mdi-text-recognition"
                >
                </v-text-field>
                <v-text-field
                  v-model="Description"
                  :counter="10"
                  label="Analyse Service Descriptio briefly *"
                  required
                  prepend-icon="mdi-text-recognition"
                >
                </v-text-field>

            

              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            small
            outlined
            class="white--text"
            @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
            Close
          </v-btn>
          <v-btn
            color="primary"
            small
            outlined
            class="white--text"
            @click="newServciceRegistration"
          >
            <v-icon>mdi-check-all</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
export default {
  props: ["OpenNewServiceForm"],
  components: {},
  data: () => ({
      SelectCategoryName:"",
    RequirmentName: "",
    Description: "",

     listofcategories:[
    "Category 1",
    "Category 2",
    "Category 3",
    "Category 4",
    "Category 5",
    "Category 6",
    "Category 7",
  ]
 
  }),

 


};
</script>