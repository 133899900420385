<template>
<div >
  <ToolBar/>
  <div style="padding:10px">
    <router-view/>
  </div>
</div>
</template>

<script>
  import ToolBar from './ToolBar.vue'

  export default {
    name: 'Home',

    components: {
      ToolBar,
    },
  }
</script>
