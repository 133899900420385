import UsersList from "./users/UsersList.vue";

export default [
    {
        path: "users",
        name: "UsersList",
        component: UsersList,
    },


]