<template>
  <v-container fluid class="my-5" app>
    <v-breadcrumbs :items="items">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
          {{ item.text.toUpperCase() }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <v-row>
      <v-col cols="8">
        <div justify="start" class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
          <v-text-field
            :value="itemsPerPage"
            label="Items per page"
            type="number"
            min="-1"
            max="15"
            @input="itemsPerPage = parseInt($event, 10)"
          ></v-text-field>
        </div>
        <v-data-table
          :headers="headers"
          :items="ServiceCollaborationAnalytics.collaborationByInstitution"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:[`item.actions`]="{}">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon color="grey lighten-1"> mdi-playlist-star </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <div class="text-overline mb-1">TCRA</div>
                    <v-list-item-title class="text-h6 mb-1">
                      Services: 11
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <div class="text-overline mb-1">TCRA</div>
                    <v-list-item-title class="text-h6 mb-1">
                      Services: 11
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <div class="text-overline mb-1">TCRA</div>
                    <v-list-item-title class="text-h6 mb-1">
                      Services: 11
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <div class="text-overline mb-1">UTUMISHI</div>
                    <v-list-item-title class="text-h6 mb-1">
                      Services: 11
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>

        <v-row justify="start" class="mt-3">
          <v-card color="rgba(177, 216, 221, 0.336)" class="mx-auto ml-1 mt-3" max-width="24%" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">G2C</div>
                <v-list-item-subtitle
                  >Services Delivery in Collaboration </v-list-item-subtitle
                >
                <v-list-item-title class="text-h6 mb-1"> {{ServiceCollaborationAnalytics.collaborationByType.g2cCount}} % </v-list-item-title>
              </v-list-item-content>
              <v-list-item-avatar>
                  <v-icon color="grey lighten-1"> mdi-account-multiple-outline </v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </v-card>
          <v-card color="rgba(221, 218, 177, 0.336)" class="mx-auto ml-1 mt-3" max-width="24%" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">G2G</div>
                <v-list-item-subtitle
                  >Services Delivery in Collaboration </v-list-item-subtitle
                >
                <v-list-item-title class="text-h6 mb-1"> {{ServiceCollaborationAnalytics.collaborationByType.g2gCount}} %  </v-list-item-title>
              </v-list-item-content>
              <v-list-item-avatar>
                  <v-icon color="grey lighten-1">mdi-flag-checkered </v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </v-card>
          <v-card color="rgba(221, 177, 212, 0.336)" class="mx-auto ml-1 mt-3" max-width="24%" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">G2B</div>
                <v-list-item-subtitle
                  >Services Delivery in Collaboration </v-list-item-subtitle
                >
                <v-list-item-title class="text-h6 mb-1"> {{ServiceCollaborationAnalytics.collaborationByType.g2bCount}} %  </v-list-item-title>
              </v-list-item-content>
              <v-list-item-avatar>
                  <v-icon color="grey lighten-1"> mdi-domain </v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </v-card>
          <v-card color="rgba(177, 221, 187, 0.336)" class="mx-auto ml-1 mt-3" max-width="24%" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">G2E</div>
                <v-list-item-subtitle
                  >Services Delivery in Collaboration </v-list-item-subtitle
                >
                <v-list-item-title class="text-h6 mb-1"> {{ServiceCollaborationAnalytics.collaborationByType.g2eCount}} %  </v-list-item-title>
              </v-list-item-content>
              <v-list-item-avatar>
                  <v-icon color="grey lighten-1"> mdi-bank-outline </v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </v-card>
        </v-row>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-card-text>
            <p class="text--primary">
              Services Delivery in Collaboration Vs. Services Delivery by Single
              Institution
            </p>
          </v-card-text>
          <most-used :dataValues="dataValues"/>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MostUsed from "./MostUsed.vue";
import { mapGetters } from "vuex";
export default {
  components: { MostUsed },
  data: () => ({
    items: [
      {
        text: "Statistics and Reports",
        disabled: false,
        href: "/private/statistics-reports",
      },
      {
        text: "Service Provision Analysis",
        disabled: true,
        href: "",
      },
    ],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    headers: [
      {
        text: "Institution",
        align: "start",
        sortable: false,
        value: "institutionName",
      },
      { text: "Services Provided", value: "serviceProvided" },
      { text: "Services Involved", value: "serviceInCollaboration" },
      { text: "%", value: "collaborationPercent" },
      { text: "Action", value: "actions" },
    ],
    desserts: [
      {
        name: "eGA",
        calories: 159,
        fat: 6.0,
        carbs: 24,
        protein: 4.0,
        iron: "1%",
      },
      {
        name: "TCRA",
        calories: 237,
        fat: 9.0,
        carbs: 37,
        protein: 4.3,
        iron: "1%",
      },
      {
        name: "UTUMISHI",
        calories: 262,
        fat: 16.0,
        carbs: 23,
        protein: 6.0,
        iron: "7%",
      },
    ],
  }),
  computed:{
    ...mapGetters({
      ServiceCollaborationAnalytics: "getServiceCollaborationAnalytics",
    }),

    dataValues(){
      return [this.ServiceCollaborationAnalytics.generalCollaborationStatus.singleProvider,this.ServiceCollaborationAnalytics.generalCollaborationStatus.coProvider]
    },

  },
};
</script>

<style>
.p{
  color:rgba(177, 221, 187, 0.336)
}
</style>