<template>
<sequential-entrance>
  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-8 text-centre">
      <h1 align="center" class="mt-10 text-centre">
        Kuhusu Daftari la Huduma za Serikali
      </h1>
      <p>
        DAFTARI LA HUDUMA ZA SERIKALI - TANZANIA (TGSD) ni mpango wa serikali wa
        kurahisisha utoaji huduma kwa umma. Mpango huu unahusisisha kuainisha
        huduma zote zinazotolewa na taasisi za umma pamoja na mahitaji yake na
        kuyaweka wazi kwa wananchi pindi wanapo pata huduma husika. Taarifa zote
        zinapatikana kupitia mtando ambapo mwananchi anaweza kuona Machakao,
        Muda, Gharama, Mahitaji, yanayo husika katika upatikanaji wa huduma
        hiyo.
      </p>
      <v-switch v-model="reverse" label="Kwa Mpangilio"></v-switch>
      <v-timeline :reverse="reverse" :dense="$vuetify.breakpoint.smAndDown">
        <v-timeline-item v-for="n in list" :key="n">
          <span slot="opposite">{{ n.name }}</span>
          <v-card class="elevation-2">
            <v-card-title class="text-h5"> {{ n.name }} </v-card-title>
            <v-card-text> {{ n.desc }} </v-card-text>
            <v-card>
              <v-subheader :inset="inset" align="center">
                Orodha ya {{ n.name }}
              </v-subheader>
              <v-list>
                <template v-for="(item, index) in n.items">
                  <v-list-item v-if="item.action" :key="item.title">
                    <v-list-item-action>
                      <v-icon>{{ item.action }}</v-icon>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title class="caption">{{
                        item.title
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider
                    v-else-if="item.divider"
                    :key="index"
                    :inset="inset"
                  ></v-divider>
                </template>
              </v-list>
            </v-card>
          </v-card>
        </v-timeline-item>
      </v-timeline>
      <v-timeline :reverse="reverse" dense> </v-timeline>
    </div>
    <div class="col-md-2"></div>
  </div>
</sequential-entrance>
</template><script>
export default {
  data: () => ({
    reverse: true,
    inset: true,
    list: [
      {
        name: "Malengo",
        desc: "Ni kurahisisha utoaji wa huduma kwa umma kwa kutoa huduma za serikali kwa ufanisi na kufikiwa kwa urahisi kupitia taarifa zilizo ndani ya Daftari la Huduma za Serikali",
        items: [
          {
            title: "Kurahisisha Mchakato wa Kupata Huduma",
            action: "mdi-label",
          },
          { divider: true },
          {
            title: "Inaokoa Muda Kuondoa Usumbufu wa Kwenda na Kurudi",
            action: "mdi-label",
          },
          { divider: true },
          {
            title:
              "Inatoa Taarifa za Gharama na Mahitaji ya Msingi ili Kupata Huduma",
            action: "mdi-label",
          },
          { divider: true },
        ],
      },
      {
        name: "Faida za TGSD",
        desc: " Taarifa zote zinapatikana kupitia mtando ambapo mwananchi anaweza kuona Machakao, Muda, Gharama, Mahitaji, yanayo husika katika upatikanaji wa huduma hiyo.",
        items: [
          {
            title: "Kurahisisha Mchakato wa Kupata Huduma",
            action: "mdi-label",
          },
          { divider: true },
          {
            title: "Inaokoa Muda Kuondoa Usumbufu wa Kwenda na Kurudi",
            action: "mdi-label",
          },
          { divider: true },
          {
            title:
              "Inatoa Taarifa za Gharama na Mahitaji ya Msingi ili Kupata Huduma",
            action: "mdi-label",
          },
          { divider: true },
        ],
      },
    ],
  }),
};
</script>