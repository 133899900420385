<template>
  <v-chart class="chart" :option="option" />
</template>

<script>
import VChart, { THEME_KEY } from "vue-echarts";

export default {
  name: "HelloWorld",
  props: ["dataLabels"],
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "white",
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [
          {
            name: 'Number of Consumer',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1048, name: 'Internal use' },
              { value: 735, name: 'Public use' },
              { value: 580, name: 'Both internal use and Public use' },
            ]
          }
        ]
      },
      computed: {},
    };
  },
};
</script>

<style scoped>
.chart {
  height: 400px;
  color: rgba(89, 70, 9, 0.137);
}
</style>
