<template>
<sequential-entrance fromTop>
 <div class="row mt-6">
    <div class="col-md-2"></div>
    <div class="col-md-8 text-centre">
<h2 align="center" class="mb-3 text-centre">Maswali ya mara kwa mara (FAQs)</h2>        
  <v-expansion-panels class="mb-10">
    <v-expansion-panel
      v-for="(item,i) in list"
      :key="i"
    >
      <v-expansion-panel-header>
        {{item.name}}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        {{item.desc}}
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
    </div>
    <div class="col-md-2"></div>
 </div>
</sequential-entrance>
</template>


<script>
export default {
  data: () => ({
    reverse: true,
    inset: true,
    list: [
      {
        name: "Napataje Leseni ya biashara ya nguo ?",
        desc: " Taarifa zote zinapatikana kupitia mtando ambapo mwananchi anaweza kuona Machakao, Muda, Gharama, Mahitaji, yanayo husika katika upatikanaji wa huduma hiyo.",
        
      },
      {
        name: "Gharama za kodi za biashara kwa mfanya biashara wa chini ni kiasi gani?",
        desc: " Taarifa zote zinapatikana kupitia mtando ambapo mwananchi anaweza kuona Machakao, Muda, Gharama, Mahitaji, yanayo husika katika upatikanaji wa huduma hiyo.",
        
      },
      {
        name: "Nikiwa na biashara natakiwa kurenew leseni kila baada ya muda gani?",
        desc: " Taarifa zote zinapatikana kupitia mtando ambapo mwananchi anaweza kuona Machakao, Muda, Gharama, Mahitaji, yanayo husika katika upatikanaji wa huduma hiyo.",
        
      },
      
      {
        name: "Nini Faida za TGSD?",
        desc: " Taarifa zote zinapatikana kupitia mtando ambapo mwananchi anaweza kuona Machakao, Muda, Gharama, Mahitaji, yanayo husika katika upatikanaji wa huduma hiyo.",
        
      },
      {
        name: "Napataje Leseni ya biashara ya nguo ?",
        desc: " Taarifa zote zinapatikana kupitia mtando ambapo mwananchi anaweza kuona Machakao, Muda, Gharama, Mahitaji, yanayo husika katika upatikanaji wa huduma hiyo.",
        
      },
      {
        name: "Gharama za kodi za biashara kwa mfanya biashara wa chini ni kiasi gani?",
        desc: " Taarifa zote zinapatikana kupitia mtando ambapo mwananchi anaweza kuona Machakao, Muda, Gharama, Mahitaji, yanayo husika katika upatikanaji wa huduma hiyo.",
        
      },
      {
        name: "Nikiwa na biashara natakiwa kurenew leseni kila baada ya muda gani?",
        desc: " Taarifa zote zinapatikana kupitia mtando ambapo mwananchi anaweza kuona Machakao, Muda, Gharama, Mahitaji, yanayo husika katika upatikanaji wa huduma hiyo.",
        
      },
      
      {
        name: "Nini Faida za TGSD?",
        desc: " Taarifa zote zinapatikana kupitia mtando ambapo mwananchi anaweza kuona Machakao, Muda, Gharama, Mahitaji, yanayo husika katika upatikanaji wa huduma hiyo.",
        
      },

{
        name: "Napataje Leseni ya biashara ya nguo ?",
        desc: " Taarifa zote zinapatikana kupitia mtando ambapo mwananchi anaweza kuona Machakao, Muda, Gharama, Mahitaji, yanayo husika katika upatikanaji wa huduma hiyo.",
        
      },
      {
        name: "Gharama za kodi za biashara kwa mfanya biashara wa chini ni kiasi gani?",
        desc: " Taarifa zote zinapatikana kupitia mtando ambapo mwananchi anaweza kuona Machakao, Muda, Gharama, Mahitaji, yanayo husika katika upatikanaji wa huduma hiyo.",
        
      },
      {
        name: "Nikiwa na biashara natakiwa kurenew leseni kila baada ya muda gani?",
        desc: " Taarifa zote zinapatikana kupitia mtando ambapo mwananchi anaweza kuona Machakao, Muda, Gharama, Mahitaji, yanayo husika katika upatikanaji wa huduma hiyo.",
        
      },
      
      {
        name: "Nini Faida za TGSD?",
        desc: " Taarifa zote zinapatikana kupitia mtando ambapo mwananchi anaweza kuona Machakao, Muda, Gharama, Mahitaji, yanayo husika katika upatikanaji wa huduma hiyo.",
        
      },

{
        name: "Napataje Leseni ya biashara ya nguo ?",
        desc: " Taarifa zote zinapatikana kupitia mtando ambapo mwananchi anaweza kuona Machakao, Muda, Gharama, Mahitaji, yanayo husika katika upatikanaji wa huduma hiyo.",
        
      },
      {
        name: "Gharama za kodi za biashara kwa mfanya biashara wa chini ni kiasi gani?",
        desc: " Taarifa zote zinapatikana kupitia mtando ambapo mwananchi anaweza kuona Machakao, Muda, Gharama, Mahitaji, yanayo husika katika upatikanaji wa huduma hiyo.",
        
      },
      {
        name: "Nikiwa na biashara natakiwa kurenew leseni kila baada ya muda gani?",
        desc: " Taarifa zote zinapatikana kupitia mtando ambapo mwananchi anaweza kuona Machakao, Muda, Gharama, Mahitaji, yanayo husika katika upatikanaji wa huduma hiyo.",
        
      },
      
      {
        name: "Nini Faida za TGSD?",
        desc: " Taarifa zote zinapatikana kupitia mtando ambapo mwananchi anaweza kuona Machakao, Muda, Gharama, Mahitaji, yanayo husika katika upatikanaji wa huduma hiyo.",
        
      },


     
    ],
  }),
};
</script>