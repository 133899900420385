<template>
  <v-container fluid class="my-5" app>
    <v-breadcrumbs :items="items">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
          {{ item.text.toUpperCase() }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <v-row>
      <v-col cols="7">
        <div class="text-overline mb-4">
          Services Affordability over service Types based on cost groups 
        </div>

        <v-row justify="start" class="mt-3">
          <v-card
            color="rgba(177, 216, 221, 0.336)"
            class="mx-auto ml-1 mt-3"
            max-width="25%"
            outlined
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">G2C</div>
                <v-list-item-subtitle
                  >For Services Cost below
                  <strong>< 50000/= Tsh</strong></v-list-item-subtitle
                >
                <v-list-item-title class="text-h6 mb-1">
                  {{ServiceAffordabilityAnalytics.serviceAffordabilityByTypes.numberOfG2c}} %
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-avatar>
                <v-icon color="grey lighten-1">
                  mdi-account-multiple-outline
                </v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </v-card>
          <v-card
            color="rgba(221, 218, 177, 0.336)"
            class="mx-auto ml-1 mt-3"
            max-width="24%"
            outlined
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">G2G</div>
                <v-list-item-subtitle
                  >For Services Cost below
                  <strong>< 50000/= Tsh</strong></v-list-item-subtitle
                >
                <v-list-item-title class="text-h6 mb-1">
                  {{ServiceAffordabilityAnalytics.serviceAffordabilityByTypes.numberOfG2g}} %
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-avatar>
                <v-icon color="grey lighten-1">mdi-flag-checkered </v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </v-card>
          <v-card
            color="rgba(221, 177, 212, 0.336)"
            class="mx-auto ml-1 mt-3"
            max-width="24%"
            outlined
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">G2B</div>
                <v-list-item-subtitle
                  >For Services Cost below
                  <strong>< 50000/= Tsh</strong></v-list-item-subtitle
                >
                <v-list-item-title class="text-h6 mb-1">
                  {{ServiceAffordabilityAnalytics.serviceAffordabilityByTypes.numberOfG2b}} %
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-avatar>
                <v-icon color="grey lighten-1"> mdi-domain </v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </v-card>
          <v-card
            color="rgba(177, 221, 187, 0.336)"
            class="mx-auto ml-1 mt-3"
            max-width="24%"
            outlined
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">G2E</div>
                <v-list-item-subtitle
                  >For Services Cost below
                  <strong>< 50000/= Tsh</strong></v-list-item-subtitle
                >
                <v-list-item-title class="text-h6 mb-1">
                  {{ServiceAffordabilityAnalytics.serviceAffordabilityByTypes.numberOfG2e}} %
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-avatar>
                <v-icon color="grey lighten-1"> mdi-bank-outline </v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </v-card>
        </v-row>
      </v-col>
      <v-col cols="5">
        <div class="text-overline mb-4">
          Affordability analysis for Servicecs Delivery in Collaboration
        </div>
        <v-expansion-panels>
          <v-expansion-panel v-for="(service, i) in ServiceAffordabilityAnalytics.serviceAffordabilityPerInstitutions" :key="service">
            <v-expansion-panel-header>
             {{service.serviceName}}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline">Group: {{service.serviceGroup}}</div>
                  <v-list-item-title
                    style="font-weight: bold"
                    class="text-h7 mb-1"
                  >
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-avatar>
                  <v-icon color="grey lighten-1">
                    mdi-account-multiple-outline
                  </v-icon>
                </v-list-item-avatar>
              </v-list-item>
              <v-list-item-subtitle class="ml-4">
                Collaboration Institutions
              </v-list-item-subtitle>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Name</th>
                      <th class="text-left">Cost</th>
                      <th class="text-left">Days</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in desserts" :key="item.name">
                      <td>{{ item.name }}</td>
                      <td>{{ item.calories }}</td>
                      <td>{{ item.calories }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <p>
                <span>
                  <p>
                    Time Affordability : <strong> TAMISEMI ( 10 Days)</strong>
                  </p>
                </span>
                <span>
                  <p>Cost Affordability : <strong> eGA ( 10M ) </strong></p>
                </span>
                <span>
                  <p>
                    Overall Affordability :
                    <strong> eGA ( 10M and 30 Days) </strong>
                  </p>
                </span>
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    items: [
      {
        text: "Statistics and Reports",
        disabled: false,
        href: "/private/statistics-reports",
      },
      {
        text: "Service Affordability Analysis",
        disabled: true,
        href: "",
      },
    ],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    headers: [
      {
        text: "Service",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Involved Partners", value: "calories" },
      { text: "Services Involved", value: "calories" },
      { text: "%", value: "iron" },
      { text: "Action", value: "actions" },
    ],
  }),
  computed:{
    ...mapGetters({
      ServiceAffordabilityAnalytics: "getServiceAffordabilityAnalytics",
    }),

    dataValues(){
      return this.ServiceAffordabilityAnalytics.serviceAffordabilityPerInstitutions
    },

  },
};
</script>

<style>
.p {
  color: rgba(177, 221, 187, 0.336);
}
</style>