import { apolloClient } from '@/vue-apollo'
import gql from 'graphql-tag'
import swal from 'sweetalert2'


export default {
    state: {
        userRoles: [],
        userList: [],
    },

    mutations: {
        cleanUserRoles(state) {
            state.userRoles = []
        },

        cleanUserList(state) {
            state.userList = []
        },

        addUserList(state, users) {
            state.userList = users
        },

        addUserRoles(state, roles) {
            state.userRoles = roles
        }
    },

    getters: {
        getUserRoles(state) {
            return state.userRoles
        },
        getSystemUsers(state){
            return state.userList
        }
    },

    actions: {
        async loadUserRoles(context) {
            context.commit('cleanUserRoles')
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: gql`
                query allRolesList{
                    allRolesList{
                        primaryKey
                        roleUniqueId
                        roleName
                        permissions{
                            primaryKey
                            permisionUniqueId
                            permisionName
                            permisionCode
                        }
                    }
                }`
            }).then((response) => {
                context.commit('addUserRoles', response.data.allRolesList)
            })
        },
        async newUserRegistration(context, userDetails) {
            context.commit('cleanUserRoles')
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: gql`mutation newUserRegistration($userObject: NewUserObjects!){
                    newUserRegistration(userObject:$userObject){
                      success
                    }
                  }`,
                variables: {
                    userObject: userDetails
                }
            }).then((response) => {
                if (response.data.newUserRegistration.success) {
                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: 'User registered successfully',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    swal.fire({
                        toast: true,
                        icon: 'warning',
                        title: 'Fail to registered user',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        async loadSystemUsers(context) {
            context.commit('cleanUserList')
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: gql`query allUsersList{
                    allUsersList{
                        primaryKey
                        firstName
                        lastName
                        userPhone
                        userPosition
                        userRole
                        username
                        userEmail
                    }
                }`
            }).then((response) => {
                context.commit('addUserList', response.data.allUsersList)
            })
        },
        async deleteSystemUser(context, userId) {
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: gql`mutation deleteSystemUser($userId: String!){
                    deleteSystemUser(userId:$userId){
                      success
                    }
                  }`,
                variables: {
                    userId: userId
                }
            }).then((response) => {
                if (response.data.deleteSystemUser.success) {
                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: 'User deleted successfully',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    swal.fire({
                        toast: true,
                        icon: 'warning',
                        title: 'Fail to delete user',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
    }
}
