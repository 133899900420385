<template>
  <v-container fluid>
    <v-row>
      <v-spacer></v-spacer>

      <v-chip
        class="ma-2"
        @click="OpenNewUserForm = !OpenNewUserForm"
        v-bind="attrs"
        v-on="on"
        color="primary"
        label
        style="font-weight: bold"
        outlined
        text-color="primary"
      >
        <v-icon left> mdi-account-plus </v-icon>
        New User
      </v-chip>

      <v-chip
        class="ma-2"
        @click="OpenNewAccessForm = !OpenNewAccessForm"
        v-bind="attrs"
        v-on="on"
        color="success"
        label
        style="font-weight: bold"
        outlined
        text-color="success"
      >
        <v-icon left> mdi-lock-plus </v-icon>
        View Roles
      </v-chip>
    </v-row>

    <v-row>
      <v-col sm="12">
        <v-card elevation="6">
          <v-tabs v-model="tab" show-arrows>
            <v-tab v-for="tab in tabs" :key="tab.icon">
              <v-icon size="20" class="me-3">
                {{ tab.icon }}
              </v-icon>
              <span>{{ tab.title }}</span>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <user-table></user-table>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <AddNewUser
      :OpenNewUserForm="OpenNewUserForm"
      @close="
        OpenNewUserForm = !OpenNewUserForm;
        userKey++;
        memberDialogKey++;
      "
      :key="userKey"
    />

    <ViewRoles
      :OpenNewAccessForm="OpenNewAccessForm"
      @close="
        OpenNewAccessForm = !OpenNewAccessForm;
        roleKey++;
      "
      :key="roleKey"
    />

    <v-dialog
      v-model="memberDialog"
      max-width="600px"
      persistent
      :key="memberDialogKey"
    >
      <v-card>
        <v-card-title>
          <v-icon>mdi-account-plus-outline</v-icon>
          <span @click="OpenNewUserForm"> Add User </span>
        </v-card-title>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AddNewUser from "./AddNewUser.vue";
import UserTable from "./UserTable.vue";
import ViewRoles from "./ViewRoles.vue";

export default {
  components: {
    AddNewUser,
    UserTable,
    ViewRoles,
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },

  data: () => ({
    tab: "",
    OpenNewUserForm: false,
    OpenNewAccessForm: false,
    tabs: [{ title: "Users List", icon: "mdi-account-star-outline" }],
  }),
  methods: {},
  async mounted(){
    await this.$store.dispatch('loadSystemUsers')
    await this.$store.dispatch('loadUserRoles')
  }
};
</script>

<style>
</style>