<template>
  <v-banner outlined shaped type="">
    <v-chart class="chart" :option="option" />
  </v-banner>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  name: "HelloWorld",
  props:  ["dataLabels","dataValues"],
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "white",
  },
  data() {
    return {
      option: {
        title: {
          text: "Top 10 most demanded requirements",
          top: "top",
        },
        legend: {
          orient: "horizontal",
          bottom: "left",
        },
        xAxis: {
          type: "category",
          data: this.dataValues,
          axisPointer: {
            type: "shadow",
          },
        },

        yAxis: {
          type: "value",
          min: 0,
        },

        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },

        toolbox: {
          show: true,
          feature: {
            dataView: { readOnly: false },
            restore: {},
            saveAsImage: {},
          },
        },
        dataZoom: {
          show: false,
          start: 0,
          end: 100,
        },

        series: [
          {
            data: this.dataLabels,
            type: "bar",
            showBackground: true,
            color:["#5470c6","#9a60b4"],
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>




