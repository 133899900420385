<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col
          v-for="analyitic in analytics"
          :key="analyitic"
          cols="12"
          sm="6"
          xs="6"
          md="4"
          lg="4"
          xl="4"
        >
          <v-hover open-delay="100">
            <v-card
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              link
              :to="analyitic.path"
              width="100%"
              
              @click="jjj"
            >
              <v-list-item two-line>
                <v-list-item-title class="text-h7">
                  <h4>{{ analyitic.name }}</h4>
                </v-list-item-title>

                <v-list-item-avatar size="90" color="rgba(29, 102, 44, 0.03)">
                  <v-icon style="font-size:72px" dark large color="#6c83eb"> {{ analyitic.icon }}</v-icon>
                </v-list-item-avatar>
              </v-list-item>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      analytics: [
        {
          name: "Service Requirment Analysis",
          icon: "mdi-book-edit",
          path:'statistics-reports/requirement-analyisis'
        },
        {
          name: "Service Type Analysis",
          icon: "mdi-ballot",
          path:'statistics-reports/service-type-analyisis'
        },
        {
          name: "Service Collaboration Analysis",
          icon: "mdi-hand-heart",
          path:'statistics-reports/service-provision-analyisis'
        },
        {
          name: "Service Delivery Time Analysis",
          icon: "mdi-clock",
          path:'statistics-reports/delivery-time-analysis'
        },
        {
          name: "Delivery Channels Analysis",
          icon: "mdi-car-speed-limiter",
          path:'statistics-reports/service-delivery-analysis'
        },
        {
          name: "Service Cost Analysis",
          icon: "mdi-cash-fast",
          path:'statistics-reports/service-cost-analysis'
        },
        {
          name: "Service Affordability Analysis",
          icon: "mdi-cash-fast",
          path:'statistics-reports/affordability-analysis'
        },
      
      ],
    }
  },
  async mounted(){
    await this.$store.dispatch('loadRequirementsAnalytics')
    await this.$store.dispatch('loadServiceTypesAnalytics')
    await this.$store.dispatch('loadServiceCollaborationAnalytics')
    await this.$store.dispatch('loadServiceDeliveryTimesAnalytics')
    await this.$store.dispatch('loadServiceCostsAnalytics')
    await this.$store.dispatch("loadServiceDeliveryChannelAnalytics")
    await this.$store.dispatch("loadServiceAffordabilityAnalytics")
  }
};
</script>
<style>
</style>