<template>
  <v-row justify="center">
    <v-dialog v-model="OpenNewAccessForm" persistent max-width="80%">
      <v-card>
        <v-card-title>
          <span class="text-h6"><v-icon>mdi-seal</v-icon> User Roles </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col md="12" cols="12">
                  <v-expansion-panels>
                    <v-expansion-panel
                      v-for="(role,i) in UserRoles"
                      :key="role.roleUniqueId"
                    >
                      <v-expansion-panel-header>
                        {{i+1}}. {{role.roleName}}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-container fluid>
                          <v-row>
                            <v-col
                              v-for="(permission) in role.permissions"
                              :key="permission"
                              cols="3"
                              sm="4"
                              md="4"
                            >
                              <v-checkbox
                              disabled
                                v-model="checkbox"
                                :label="permission.permisionName"
                                  color="primary"
                                hide-details
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            outlined
            small
            class="white--text"
            @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon> Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import {mapGetters} from 'vuex'
export default {
  props: ["OpenNewAccessForm"],
  components: {},
    data () {
      return {
        checkbox: true,
      }
    },

  methods: {},

  computed:{
      ...mapGetters({
          UserRoles:"getUserRoles"
      }),
  }

};
</script>