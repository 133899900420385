<template>
  <v-chart class="chart" :option="option" />
</template>

<script>
import VChart, { THEME_KEY } from "vue-echarts";

export default {
  name: "HelloWorld",
  props: ["data"],
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "white",
  },
  data() {
    return {
      option: {

  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  legend: {},
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  xAxis: {
    type: 'value',
    boundaryGap: [0, 0.01]
  },
  yAxis: {
    type: 'category',
    data: this.data.labels
  },
  series: [
    {
      name: '0 - 50,000 Tsh',
      type: 'bar',
      data: this.data.firtsData
    },
    {
      name: '50,000 - 100,000 Tsh',
      type: 'bar',
      data:  this.data.secondData
    },
    {
      name: '100,000 - 500,000 Tsh',
      type: 'bar',
      data:  this.data.thirdData
    },
    {
      name: '500,000 Tsh > ',
      type: 'bar',
      data:  this.data.fouthData
    }
  ]
},
      computed: {},
    };
  },
};
</script>

<style scoped>
.chart {
  height: 400px;
  color:rgba(89, 70, 9, 0.137),
}
</style>


