<template>
  <v-app>
    <v-row align="center" justify="center">
          <v-col cols="12">
            <v-img src="@/assets/banner.png"></v-img>
          </v-col>
        </v-row>
    <nav>
      <v-app-bar
        dense
        dark
        color="#5596e6"
        class="lighten-8  darken-4 hidden-sm-and-down"
      >
        <v-app-bar-nav-icon>
          <img src="../../assets/emblem.png" width="45" alt="Logo" />
        </v-app-bar-nav-icon>
        <v-toolbar-title class="text-uppercase grey--text">
          <span class="white--text ">Daftari la Huduma za Serikali - Tanzania </span>
          <span class="white--text font-weight-light"> (TGSD)</span>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <div>
          <v-btn link to="/" text router >
            <v-icon small>mdi-home-outline</v-icon>
          </v-btn>
        </div>
        <div>
          <v-btn link to="/AboutLanding" text router >
            <v-icon small>mdi-seal</v-icon>
            <span class="mr-1">KUHUSU TGSD</span>
          </v-btn>
        </div>

        <div>
          <v-btn href="http://196.192.78.30/" target="_new" text router >
            <v-icon small>mdi-checkbox-marked-circle-outline</v-icon>
            <span class="mr-1">DODOSO</span>
          </v-btn>
        </div>

        <div>
          <v-btn link to="/FqasLanding" text router >
            <v-icon small>mdi-file-table-outline</v-icon>
            <span class="mr-1">MWONGOZO WA MTUMIAJI</span>
          </v-btn>
        </div>

        <div>
          <v-btn link to="/login" text router >
            <v-icon small>mdi-lock-open</v-icon>
            <span class="mr-1">LOGIN</span>
          </v-btn>
        </div>

        <v-app-bar-nav-icon href="https://www.nbs.go.tz/index.php/en/" target="_blank">
          <img  src="../../assets/census_sw.png" width="60" alt="Logo" />
        </v-app-bar-nav-icon>
      </v-app-bar>
    </nav>

    <v-main scroll class="hero">
      <router-view></router-view>
    </v-main>

  </v-app>
    <!-- <v-footer padless color="#ffffff00">
      <v-col class="text-center" cols="12">
        <h5>Ofisi ya Rais - Menejimenti ya Utumishi wa Umma na Utawala Bora</h5>
          <strong>TGSD</strong>@{{ new Date().getFullYear() }}
      </v-col>
    </v-footer> -->
</template>

<script>
export default {
  name: "Landing",

  data() {
    return {
      board_account: "/board_account/",
      drawer: true,
      currentBoard: "My Board",
      mini: true,
    };
  },
};
</script>

<style >
.hero {
  background: url("../../assets/mtnew.png");
  background-attachment: fixed;
  background-size: cover;
  /* height: 100vh; */
}
</style>

<style>
.scroll {
  overflow-y: scroll;
}
/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #556fe6;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #556fe6;
}
</style>