<template>
  <v-container fluid class="my-5" app>
    <v-breadcrumbs :items="items">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
          {{ item.text.toUpperCase() }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <v-row>
      <v-col cols="5">
        <v-card>
          <v-card-text>
            <p class="text-h6 text--primary">Services per Categories</p>
          </v-card-text>
          <most-used  :dataLabels="dataLabels"  :dataValues="dataValues" />
        </v-card>
      </v-col>
      <v-col cols="7">
        <div justify="start" class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
          <v-text-field
            :value="itemsPerPage"
            label="Items per page"
            type="number"
            min="-1"
            max="15"
            @input="itemsPerPage = parseInt($event, 10)"
          ></v-text-field>
        </div>
        <v-data-table
          :headers="headers"
          :items="ServiceTypeAnalytics.institutionServicesType"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          class="elevation-1"
        ></v-data-table>
        <div class="text-overline mb-4">
          Institution that offers many G2C Services
        </div>
        <v-card class="mx-auto mb-3" max-width="100%" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-1">{{ServiceTypeAnalytics.mostG2cInstitution.institutionName}}</div>
              <v-list-item-title class="text-h6 mb-1">
                No. of G2C Services: {{ServiceTypeAnalytics.mostG2cInstitution.numberOfServices}}
              </v-list-item-title>
              <v-list-item-subtitle
                >Citizens orriented Services delivery
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon color="grey lighten-1"> mdi-playlist-star </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="service in ServiceTypeAnalytics.mostG2cInstitution.serviceList" :key="service">
                    <v-list-item-title
                      >{{service.serviceName}}</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MostUsed from "./MostUsed.vue";
import { mapGetters } from "vuex";

export default {
  components: { MostUsed },
  data: () => ({
    items: [
      {
        text: "Statistics and Reports",
        disabled: false,
        href: "/private/statistics-reports",
      },
      {
        text: "Service Types Analysis",
        disabled: true,
        href: "",
      },
    ],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    headers: [
      {
        text: "Institution",
        align: "start",
        sortable: false,
        value: "institutionName",
      },
      { text: "No. Services", value: "totalServices" },
      { text: "G2C", value: "numberOfG2c" },
      { text: "G2B", value: "numberOfG2b" },
      { text: "G2G", value: "numberOfG2g" },
      { text: "G2E", value: "numberOfG2e" },
    ],
  }),
  computed:{
    ...mapGetters({
      ServiceTypeAnalytics: "getServiceTypeAnalytics",
    }),

    dataLabels(){
      var temp_list=[]
      this.ServiceTypeAnalytics.servicePerCategory.forEach(label => {
          temp_list.push(label.name)
      });
      return temp_list
    },


    dataValues(){
      var temp_list=[]
      this.ServiceTypeAnalytics.servicePerCategory.forEach(data => {
          temp_list.push(data.value)
      });
      return temp_list
    },

  },
  async mounted(){
    await this.$store.dispatch('cc')
  }
};
</script>

<style>
</style>