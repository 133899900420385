import { apolloClient } from '@/vue-apollo'
import gql from 'graphql-tag'

export default {
    state: {
        serviceCollaborationAnalytics: {}
    },

    mutations: {
        addServiceCollaborationAnalytics(state, data) {
            state.serviceCollaborationAnalytics = data
        },
    },

    getters: {
        getServiceCollaborationAnalytics(state) {
            return state.serviceCollaborationAnalytics
        },
    },

    actions: {
        async loadServiceCollaborationAnalytics(context) {
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: gql`query serviceCollaborationAnalytics{
                  serviceCollaborationAnalytics{
                    generalCollaborationStatus{
                      singleProvider
                      coProvider
                    }
                    collaborationByInstitution{
                      institutionName
                      serviceProvided
                      serviceInCollaboration
                      collaborationPercent
                      servicesList{
                        name
                        value
                      }
                    }
                    collaborationByType{
                      g2cName
                      g2cCount
                      g2gName
                      g2gCount
                      g2bName
                      g2bCount
                      g2eName
                      g2eCount
                    }
                  }
                }`
            }).then((response) => {
                context.commit('addServiceCollaborationAnalytics', response.data.serviceCollaborationAnalytics)
            })
        },
    }
}
