import Vue from 'vue'
import VueRouter from 'vue-router'
import PublicRouter from "../modules/public/PublicRouter";
import PrivateRouter from "../modules/private/PrivateRouter";
import Landing from "../components/shared/Landing.vue";
import SettingRouter from '@/modules/settings/SettingRouter';
import SurveyResponseLayout from "../components/shared/Layout.vue";
import LoginLanding from "../modules/public/auth/LoginLanding.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: "/login",
    name:"LandingLogin",
    component: LoginLanding,
 },
  {
    path: "/",
    name: "Landing",
    component: Landing,
    children: [
      ... PublicRouter,
    ],
  },
  {
    path: "/private",
    name: "private",
    component: SurveyResponseLayout,
    children: [
      ... PrivateRouter,
    ],
  },

  {
    path: "/settings",
    name: "settings",
    component: SurveyResponseLayout,
    children: [
      ... SettingRouter,
    ],
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
