import { apolloClient } from '@/vue-apollo'
import gql from 'graphql-tag'

export default {
    state: {
        requirementsAnalytics: {}
    },

    mutations: {
        clearRequirementsAnalytics(state) {
            state.requirementsAnalytics = {}
        },
        addRequirementsAnalytics(state, data) {
            state.requirementsAnalytics = data
        },
    },

    getters: {
        getRequirementsAnalytics(state) {
            return state.requirementsAnalytics
        },
    },

    actions: {
        async loadRequirementsAnalytics(context) {
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: gql`query requirementAnalyitics{
                    requirementAnalyitics{
                      topFiveUsage{
                        requirementName
                        requirementUsage
                      }
                      requirementsUsage{
                        requirementName
                        requirementUsage
                      }
                      serviceRequirements{
                        serviceName
                        serviceProvider
                        serviceRequirements
                        serviceRequirementsList{
                          requirementName
                        }
                      }
                      institutionsRequirements{
                        institutionName
                        numberOfServices
                        numberOfRequirements
                      }
                    }
                  }`
            }).then((response) => {
                context.commit('addRequirementsAnalytics', response.data.requirementAnalyitics)
            })
        },
    }
}
