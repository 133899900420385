import { apolloClient } from '@/vue-apollo'
import gql from 'graphql-tag'

export default {
    state: {
        ServiceDeliveryChannelAnalytics: {}
    },

    mutations: {
            addServiceDeliveryChannelAnalytics(state, data) {
                state.ServiceDeliveryChannelAnalytics = data
            },
    },

    getters: {
        getServiceDeliveryChannelAnalytics(state) {
            return state.ServiceDeliveryChannelAnalytics
        },
    },

    actions: {
        async loadServiceDeliveryChannelAnalytics(context) {
            await apolloClient.query({
                fetchPolicy:'no-cache',
                query:gql ` query serviceDeliveryChannelsAnalytics{
                    serviceDeliveryChannelsAnalytics{
                        generalAutomationStatus{
                        autonatedServices
                        unAutonatedServices
                      }
                      generalServiceTypesAutomationStatus{
                        percentageOfG2c
                        percentageOfG2b
                        percentageOfG2g
                        percentageOfG2e
                      }
                      sectorialServiceAutomationStatus{
                        sectorName
                        percentageOfAutomation
                      }
                      channelsServiceAutomationStatus{
                        channelName
                        channelPercentage
                      }
                      consumersServiceAutomationStatus{
                        consumerName
                        consumerPercentage
                      }
                    }
                  }`
            }).then((response) => {
                context.commit('addServiceDeliveryChannelAnalytics', response.data.serviceDeliveryChannelsAnalytics)
            })
        }
    }
}