

<template>
  <v-data-table
    :headers="headers"
    :items="SystemUsers"
    :items-per-page="5"
    class="elavation-1 mt-6"
  >
      <template v-slot:[`item.actions`]="{ item }">

      <v-icon
        small
        @click="deleteSystemUser(item.primaryKey)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    headers: [
      {
        text: "First Name",
        align: "start",
        sortable: false,
        value: "firstName",
      },
      {
        text: "Last Name",
        align: "start",
        sortable: false,
        value: "lastName",
      },
      { text: "Email", value: "userEmail" },
      { text: "Position", value: "userPosition" },
      { text: "Phone", value: "userPhone" },
      { text: "User Role", value: "userRole" },
      { text: "Actions", value: "actions" },
    ],
  }),

  computed: {
    ...mapGetters({
      SystemUsers: "getSystemUsers",
    }),
  },

  methods: {
    async deleteSystemUser(user_id) {
      console.log(user_id);
      await this.$store.dispatch("deleteSystemUser", user_id);
      await this.$store.dispatch('loadSystemUsers')
    },
  },

};
</script>

<style>
v-btn:hover {
  background-color: rgb(189, 213, 235);
  color: rgb(24, 28, 32);
}
thead .text-start {
  color: #000922 !important;
  font-size: medium !important;
  background-color: rgb(247, 243, 231);
}


</style>