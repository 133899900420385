<template>
      <nav>
      <v-app-bar color="#5596e6" dense app>
        <v-app-bar-nav-icon
          ><v-icon @click="mini = !mini" class="white--text" left
            >mdi-menu</v-icon
          ></v-app-bar-nav-icon
        >
        <v-toolbar-title class="text-uppercase grey--text">
          <span class="white--text">T-</span>
          <span class="white--text font-weight-light">GSD / </span>
          <span class="white--text">P</span>
          <span class="white--text text-lowercase">ortal</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon>
          <router-link to="/" style="text-decoration: none">
            <v-icon class="white--text" color="white" left>mdi-home</v-icon>
          </router-link>
        </v-app-bar-nav-icon>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="white--text"
              v-bind="attrs"
              v-on="on"
              slot="activator"
              right
              >mdi-chevron-down</v-icon
            >
            <span style="padding-left: 10px;color:white">{{ logeduser.username }}</span>
          </template>
        <v-list>
          <v-list-item style="cursor:pointer" @click="callLogoutMethod">
            <v-list-item-content>
              <v-list-item-title
                ><v-icon>mdi-power</v-icon> Logout</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
        </v-menu>
      </v-app-bar>

      <v-card>
      <v-navigation-drawer
        v-model="drawer"
        :mini-variant.sync="mini"
        permanent
        app
      >
          <div class="text-center indigo--text darken-4">
            <v-img
              src="../../assets/emblem.png"
              max-width="100px"
              class="d-block ml-auto mr-auto mt-3 mb-3"
            ></v-img>
          </div>

          <v-divider></v-divider>

        <v-list dense>
          <template v-for="link in links">
            <v-list-item
              link
              :to="link.routinglink"
              :key="link.name"
              v-if="hasAccess(link.permision)"
            >
              <v-list-item-icon class="iconclass">
                <v-icon style="color:#6c83eb">{{
                  link.icon
                }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ link.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-navigation-drawer>
      </v-card>







    </nav>
</template>


<script>
import store from '../../store'
export default {
  name: "private",

  data() {
    return {
      logeduser:{'username':""},
      board_account: "/board_account/",
      drawer: true,
      currentBoard: "My Board",
      mini: false,
      links: [
       {
        name:'Dashboard',
        icon:'mdi-home-outline',
        routinglink:'/private/dashboard',
        permision: "can_access_reports",
    },
    {
        name:'Dodoso Response',
        icon:'mdi-file-table-outline',
        routinglink:'/private/survey-responses',
        permision: "can_access_reports",
    },
    {
        name:'Statistics and Reports',
        icon:'mdi-chart-bar-stacked',
        routinglink:'/private/statistics-reports',
        permision: "can_access_reports",
    }, 
    {
        name:'Users and Roles',
        icon:'mdi-account-group-outline',
        routinglink:'/settings/users',
        permision: "can_manage_users",
    },
      ],
    };
  },

  mounted(){
    const userDetails = store.getters.user
    this.logeduser=userDetails
  }

};
</script>


<style>

</style>