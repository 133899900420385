<template>
  <v-card color="basil">
    <v-card-title class="text-center justify-center py-6">
      <h2 class="font-weight-bold green--text" >
        Institution Services
      </h2>
    </v-card-title>

    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="rgb(230, 188, 34)"
      class="font-weight-bold"
      grow
    >
      <v-tab
        v-for="item in items" :key="item">
        {{ item }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="item in items"
        :key="item"
      >
        <v-card
          color="basil"
          flat
        >
          <v-card-text>{{ text }}</v-card-text>

            <v-data-table
              :headers="headers"
              :items="institutionsSurveyResponses"
              :items-per-page="5"
              class="elevation-1"
            >
             <template v-slot:item.calories="{ item }">
                <v-chip
                  :color="getColor(item.calories)"
                  dark
                  fab
                  small 
                  class="mx-5"
                >
                  {{ item.calories }}
                </v-chip>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-row>
                     <v-btn dark fab  x-small color="green" class="mx-5" link to="/private/response-Services">
                    <v-icon small>mdi-eye</v-icon>
                </v-btn>

                </v-row>
                
            </template>
            
            </v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>



<script>
import { mapGetters } from "vuex";
 
  export default {
    data () {
      return {
        tab: null,
        items: [
          'List of Institution services',
        ],
     
     headers: [
          {
            text: 'Institution Name',
            align: 'start',
            sortable: false,
            value: 'institutionName',
          },
          { text: 'Number of Services', value: 'institutionServices' },
          { text: 'Action', value: 'actions' },
        ],

     }
    },
     methods: {
      getColor (calories) {
        if (calories > 400) return 'green'
        else if (calories > 200) return 'green'
        else return 'green'
      },
    },

   computed: {
    ...mapGetters({
      institutionsSurveyResponses:"getInstitutionListAnalytics"
    }),

   },
    async mounted() {
    await this.$store.dispatch("loadInstitutionList");
    },
    
  }
</script>