<template>
  <v-chart class="chart" :option="option" />
</template>

<script>
import VChart, { THEME_KEY } from "vue-echarts";

export default {
  name: "HelloWorld",
  props: ["data"],
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "white",
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "horizontal",
          bottom: "left",
        },
        series: [
          {
            name: 'Percentage of  Services',
            type: "pie",
            radius: "70%",
            center: ["50%", "50%"],
            data: this.data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              }
            },
          },
        ],
      },
      computed: {},
    };
  },
};
</script>

<style scoped>
.chart {
  height: 400px;
  color: rgba(89, 70, 9, 0.137);
}
</style>
