<template>
  <v-container fluid class="my-5" app>
    <v-breadcrumbs :items="items">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
          {{ item.text.toUpperCase() }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <v-row>
      
      <v-col cols="5">
        <v-card>
          <v-card-text>
            <p class="text-h6 text--primary">Top 5 Time Required Services</p>
          </v-card-text>
          <most-used :data="timeRequiredServices"/>
        </v-card>
      </v-col>

      <v-col cols="7">
        <v-card>
          <v-card-text>
            <p class="text-h6 text--primary">Sectorial Average Time of Service Delivery (Days)</p>
          </v-card-text>
          <BarCharts :dataLabels="dataLabels" :dataValues="dataValues"/>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div class="text-overline mb-4">Institutional Average time on Services Delivery</div>
        <div justify="start" class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
          <v-text-field
            :value="itemsPerPage"
            label="Items per page"
            type="number"
            min="-1"
            max="15"
            @input="itemsPerPage = parseInt($event, 10)"
          ></v-text-field>
        </div>
        <v-data-table
          :headers="headers"
          :items="ServiceDeliveryTimeAnalytics.institutionServicesAverageTime"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          class="elevation-1"
        ></v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MostUsed from "./MostUsed.vue";
import BarCharts from "./BarCharts.vue";
import { mapGetters } from "vuex";
export default {
  components: {
     MostUsed,
     BarCharts,
      },
  data: () => ({
    items: [
      {
        text: "Statistics and Reports",
        disabled: false,
        href: "/private/statistics-reports",
      },
      {
        text: "Service Delivery Time Analysis",
        disabled: true,
        href: "",
      },
    ],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    headers: [
      {
        text: "Institution Name",
        align: "start",
        sortable: false,
        value: "institutionName",
      },
      { text: "Institution Sector", value: "institutionSector" },
      { text: "No. Services", value: "totalServices" },
      { text: "Average Time (Days)", value: "timeAverage" },
    ],

    }),
  
  computed:{
      ...mapGetters({
        ServiceDeliveryTimeAnalytics: "getServiceDeliveryTimeAnalytics",
      }),

    dataLabels(){
      var sectorial=[]
      this.ServiceDeliveryTimeAnalytics.sectorialServicesAverageTime.forEach(data => {
          sectorial.push(data.sectorName)
      });
      return sectorial
    },

    dataValues(){
      var sectorial=[]
      this.ServiceDeliveryTimeAnalytics.sectorialServicesAverageTime.forEach(data => {
          sectorial.push(data.timeAverage)
      });
      return sectorial
    },

    timeRequiredServices(){
      var data=[]
      this.ServiceDeliveryTimeAnalytics.mostTimeConsumaleServices.forEach((element) => {
            data.push({ value:element.duration, name: element.service })
    });
      return data
    }
    },
      
      async mounted() {
      await this.$store.dispatch("loadServiceCostsAnalytics");
    },

  
};
</script>

<style>
</style>