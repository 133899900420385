<template>
  <v-chart class="chart" :option="option" />
</template>

<script>
import VChart, { THEME_KEY } from "vue-echarts";

export default {
  name: "HelloWorld",
  props: ["dataLabels","dataValues"],
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "white",
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: this.dataLabels,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "Number of  Services",
            type: "bar",
            barWidth: "60%",
            data: this.dataValues,
            showBackground: true,
            color: ["#5470c6", "#9a60b4"],
            backgroundStyle: {
              color: "rgba(89, 70, 9, 0.137)",
            },
          },
        ],
      },
      computed: {},
    };
  },
};
</script>

<style scoped>
.chart {
  height: 400px;
  color: rgba(89, 70, 9, 0.137);
}
</style>
