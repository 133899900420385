<template>
  <v-row justify="center">
    <v-dialog v-model="OpenNewUserForm" persistent max-width="80%">
      <v-card>
        <v-card-title>
          <span class="text-h6"
            ><v-icon>mdi-account-plus-outline</v-icon> Add New Person to
            TGSD</span
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="FirstName"
                  :counter="10"
                  label="First Name *"
                  required
                  prepend-icon="mdi-text-recognition"
                >
                </v-text-field>
                <v-text-field
                  v-model="LastName"
                  :counter="10"
                  label="Last Name *"
                  required
                  prepend-icon="mdi-text-recognition"
                >
                </v-text-field>

                <v-text-field
                  v-model="email"
                  :counter="10"
                  label="Email Address*"
                  required
                  prepend-icon="mdi-text-recognition"
                >
                </v-text-field>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="position"
                      :counter="10"
                      label="Position"
                      required
                      prepend-icon="mdi-text-recognition"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="Phone"
                      :counter="10"
                      label="Phone"
                      required
                      prepend-icon="mdi-text-recognition"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-radio-group v-model="userrole">
                  <template v-slot:label>
                    <div>Select / Assign User <strong> Role</strong></div>
                  </template>

                  <v-radio
                    v-for="role in UserRoles"
                    :key="role.roleUniqueId"
                    :value="role.roleUniqueId"
                  >
                    <template v-slot:label>
                      <div>{{ role.roleName }}</div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            small
            outlined
            class="white--text"
            @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
            Close
          </v-btn>
          <v-btn
            color="primary"
            small
            outlined
            class="white--text"
            @click="newUserRegistration"
          >
            <v-icon>mdi-check-all</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import { mapGetters } from "vuex";
export default {
  props: ["OpenNewUserForm"],
  components: {},
  data: () => ({
    FirstName: "",
    LastName: "",
    email: "",
    position: "",
    userrole: null,
    Phone: "",
  }),

  methods: {
    async newUserRegistration() {
      var userDetails = {
        userRole: this.userrole,
        lastName: this.LastName,
        firstName: this.FirstName,
        email: this.email,
        position: this.position,
        phone: this.Phone,
      };
      await this.$store.dispatch("newUserRegistration", userDetails);
      await this.$store.dispatch('loadSystemUsers')
      this.$emit("close");
    },
  },

  computed: {
    ...mapGetters({
      UserRoles: "getUserRoles",
    }),
  },
  async mounted(){
    await this.$store.dispatch('loadUserRoles')
  }
};
</script>