import HomeLanding from "./pages/HomeLanding.vue";
import AboutLanding from "./pages/AboutLanding.vue";
import FqasLanding from "./pages/FqasLanding.vue";
import SurveyLanding from "./pages/SurveyLanding.vue";
import ServiceDetails from "./pages/ServiceDetails.vue";



export default [
{
    path: "",
    name:"LandingHome",
    component: HomeLanding,
},
 
 {
    path: "AboutLanding",
    name:"LandingAbout",
    component: AboutLanding,
 },
 {
    path: "FqasLanding",
    name:"LandingFqas",
    component: FqasLanding,
 },
 {
    path: "SurveyLanding",
    name:"LandingSurvey",
    component: SurveyLanding,
 },
 {
   path: "ServiceDetails",
   name:"ServiceDetails",
   component: ServiceDetails,
},
]