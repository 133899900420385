<template>
  <v-row justify="center">
    <v-dialog v-model="OpenNewCategoryForm" persistent max-width="80%">
      <v-card>
        <v-card-title>
          <span class="text-h6"
            ><v-icon>mdi-view-dashboard-edit-outline</v-icon> Add New Category
            </span
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                
                <v-text-field
                  v-model="CategoryName"
                  :counter="10"
                  label="Category Name *"
                  required
                  prepend-icon="mdi-text-recognition"
                >
                </v-text-field>
                <v-text-field
                  v-model="Description"
                  :counter="10"
                  label="Category Description *"
                  required
                  prepend-icon="mdi-text-recognition"
                >
                </v-text-field>

            

              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            small
            outlined
            class="white--text"
            @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
            Close
          </v-btn>
          <v-btn
            color="primary"
            small
            outlined
            class="white--text"
            @click="newCategoryRegistration"
          >
            <v-icon>mdi-check-all</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
export default {
  props: ["OpenNewCategoryForm"],
  components: {},
  data: () => ({
    CategoryName: "",
    Description: "",
 
  }),


};
</script>