<template>
  <div>
      <h2 >{{title}}</h2>
    <v-data-table
      :headers="headers"
      :items="desserts"
      item-key="name"
      class="elevation-1"
      :search="search"
      :custom-filter="filterOnlyCapsText"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search "
          class="mx-4"
        ></v-text-field>
      </template>
       <template >
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row >
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.name"
                      label="Dessert name"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.calories"
                      label="Calories"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.fat"
                      label="Fat (g)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.carbs"
                      label="Carbs (g)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.protein"
                      label="Protein (g)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
    </template>
    <template v-slot:item.actions="{ item }">
     
      <v-icon
      small 
      color="primary"
      @click="navigateToServices"
      >
        mdi-eye
      </v-icon>
      

    </template>
    
    </v-data-table>
  </div>
</template>






<script>
  export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
        title:'All Approved Services',
        search: '',
      headers: [
        {
          text: 'Institution Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Service Name', value: 'g2g' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      defaultItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },
computed: {
      headers () {
        return [
          {
            text: 'Institution Name',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          
          { text: 'SERVICE NAME', value: 'g2g' },
          
          { text: 'Iron (%)', value: 'iron' },
        ]
      },
    },
    
    methods: {
      initialize () {
        this.desserts = [
          {
            name: 'e-Governement Authority',
            g2g: 'Govenment Mailigy System (GMS)',
            
          },
          {
            name: 'UTUMISHI Wa UMMA Na Utawala BOra',
            g2g: 'eDodoso',
            
          },
          {
            name: 'Tanzania Communication Technology Authority',
            g2g: 'Single Window',
            
          },
          {
            name: 'Tanzania Revenue Authority',
            g2g: 'Govenement Taxation System',
            
          },
          
          
        ]
      },
       filterOnlyCapsText (value, search, item) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search) !== -1
      },

      viewInstitution (item) {
       console.log(item)
      },

     
      navigateToServices(){
          this.$router.push('/private/response-Services')

      },

    },
  }
</script>