import { apolloClient } from '@/vue-apollo'
import gql from 'graphql-tag'

export default {
    state: {
        dashboardAnalytics: {},
        serviceTypeAnalytics: {},
        institutionListAnalytics: {}

    },

    mutations: {
        clearDashboardAnalytics(state) {
            state.dashboardAnalytics = {}
        },
        addDashboardAnalytics(state, data) {
            state.dashboardAnalytics = data
        },
        addServiceTypesAnalytics(state, data) {
          state.serviceTypeAnalytics = data
        },
        addInstitutionListAnalytics(state, data) {
          state.institutionListAnalytics = data
        },
    },

    getters: {
        getDashboardAnalytics(state) {
            return state.dashboardAnalytics
        },
        getServiceTypeDashboardAnalytics(state) {
          return state.serviceTypeAnalytics
        },
        getInstitutionListAnalytics(state) {
          return state.institutionListAnalytics
        },
    },

    actions: {
        async loadDashboardAnalytics(context) {
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: gql`query requirementAnalyitics{
                    requirementAnalyitics{
                      topFiveUsage{
                        requirementName
                        requirementUsage
                      }
                      requirementsUsage{
                        requirementName
                        requirementUsage
                      }
                      serviceRequirements{
                        serviceName
                        serviceProvider
                        serviceRequirements
                        serviceRequirementsList{
                          requirementName
                        }
                      }
                      institutionsRequirements{
                        institutionName
                        numberOfServices
                        numberOfRequirements
                      }
                    }
                  }`
            }).then((response) => {
                context.commit('addDashboardAnalytics', response.data.requirementAnalyitics)
            })
        },

      async loadServiceTypesAnalytics(context) {
        await apolloClient.query({
            fetchPolicy: 'no-cache',
            query: gql`query serviceTypesAnalytics{
              serviceTypesAnalytics{
                servicePerCategory{
                  name
                  value
                }
                mostG2cInstitution{
                  institutionName
                  numberOfServices
                  serviceList{
                    serviceName
                  }
                }
                institutionServicesType{
                  institutionName
                  totalServices
                  numberOfG2c
                  numberOfG2g
                  numberOfG2e
                  numberOfG2b
                }
              }
            }`
        }).then((response) => {
            context.commit('addServiceTypesAnalytics', response.data.serviceTypesAnalytics)
        })
    },


    async loadInstitutionList(context) {
      await apolloClient.query({
          fetchPolicy:'no-cache',
          query: gql`query institutionsSurveyResponses{
              institutionsSurveyResponses{
                institutionUniqueId
                institutionName
                institutionServices
                institutionCompletedServices
                institutionRemainServices
              }
            }`
      }).then((response) => {
          context.commit('addInstitutionListAnalytics', response.data.institutionsSurveyResponses)
      })
  },

  
    }
}
