import dashboard from "./dashboard/DashBoard.vue";
import SurveyResponses from "./dodoso/SurveyResponses.vue";
import ResponseServices from "./dodoso/ResponseServices.vue";
import ApprovedServices from "./dodoso/ApprovedServices.vue";
import ReportStatistics from "./analysis/ReportStatistics.vue";
import ListOfServices from "./dodoso/ListOfServices.vue";
import LIstOfCategory from "./dashboard/LIstOfCategory.vue";
import ListOfRequirments from "./dashboard/ListOfRequirments.vue"
import ListOfServicesDashboard from "./dashboard/LIstOfServices.vue"
import InstitutionServiceList from "./dashboard/InstitutionServiceList.vue"
import RequirementAnalysis from '../private/analysis/requirements/RequirementAnalysis.vue'
import ServiceTypeAnalysis from '../private/analysis/service-type/ServiceTypeAnalysis.vue'
import ServiceProvisionAnalysis from '../private/analysis/provision/ServiceProvisionAnalysis.vue'
import DeliveryTimeAnalysis from '../private/analysis/service-time/DeliveryTimeAnalysis.vue'
import ServiceAffordabilityAnalysis from '../private/analysis/affordability/ServiceAffordabilityAnalysis.vue'
import ServiceCostAnalysis from '../private/analysis/service-cost/ServiceCostAnalysis.vue'
import ServiceDeliveryAnalysis from '../private/analysis/service-delivery/ServiceDeliveryAnalysis.vue'


export default [
    {
        path: "dashboard",
        name:"dashboard",
        component: dashboard,
    },
    {
       path: "statistics-reports",
       name:"ReportStatistics",
       component: ReportStatistics,
    },
    {
       path: "statistics-reports/requirement-analyisis",
       name:"RequirementAnalysis",
       component: RequirementAnalysis,
    },
    {
       path: "statistics-reports/service-type-analyisis",
       name:"RequirementAnalysis",
       component: ServiceTypeAnalysis,
    },
     {
        path: "statistics-reports/service-provision-analyisis",
        name:"ServiceProvisionAnalysis",
        component: ServiceProvisionAnalysis,
     },
     {
      path: "statistics-reports/service-cost-analysis",
      name:"ServiceCostAnalysis",
      component: ServiceCostAnalysis,
   },
   {
      path: "statistics-reports/delivery-time-analysis",
      name: "DeliveryTimeAnalysis",
      component: DeliveryTimeAnalysis,
   },
   {
      path: "statistics-reports/affordability-analysis",
      name: "ServiceAffordabilityAnalysis",
      component: ServiceAffordabilityAnalysis,
   },
   {
      path: "statistics-reports/service-delivery-analysis",
      name: "ServiceDeliveryAnalysis",
      component: ServiceDeliveryAnalysis,
   },
   
   {
      path: "institution-service-list",
      name: "InstitutionServiceList",
      component: InstitutionServiceList,
     },
     {
      path:"list-of-requirments",
      name:"LIstOfRequirments",
      component: ListOfRequirments,
     },
     {
      path:"list-of-category",
      name:"LIstOfCategory",
      component: LIstOfCategory,
     },
     {
         path: "list-of-services-Dashboard",
         name:"ListOfServicesDashboard",
         component: ListOfServicesDashboard,
      },
      {
          path: "list-of-services",
          name:"ListOfServices",
          component: ListOfServices,
       }, 
     
      {
         path: "survey-responses",
         name:"SurveyResponses",
         component: SurveyResponses,
      },
     
     {
        path: "response-Services/:institutionUniqueId",
        name:"Response-Services",
        component: ResponseServices,
     },
     {
        path: "approved-Service",
        name:"Approved-Service",
        component: ApprovedServices,
     },
     
     
    ]
  
  
