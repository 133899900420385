<template>
  <v-container fluid>
    <v-btn
      outlined
      small
      class="mb-7 mx-4"
      color="rgb(230, 188, 34)"
      link
      to="/private/survey-responses"
    >
      <v-icon color=""> mdi-arrow-left </v-icon>
    </v-btn>
    <v-card class="mb-5">
      <div class="col-12 row">
        <v-card-subtitle class="pl-5 justify-center">
          <h3
            style="font-weight: bold"
            class="justify-center mr-2 indigo--text"
          >
            Institution Information
          </h3>

          <v-list-item one-line>
            <v-list-item-content>
              <v-list-item-subtitle
                style="font-weight: bold"
                class="text-wrap mr-5"
                >Jina la Taasisi: <span> e-Governement Authority Services </span
                ><BR /> Wizara Iliyopo:
                <span>Utumishi </span>
                <br />
                nuani ya Taasisi:
                <span> P.O BOX 2383</span><BR />
                Sekta Iliyopo:
                <span>Utumishi </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-subtitle>

        <v-spacer></v-spacer>
        <v-dialog transition="dialog-top-transition" max-width="900">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="rgb(230, 188, 34)"
              v-bind="attrs"
              v-on="on"
              outlined
              small
              class="black--text"
              ><v-icon dense left>mdi-eye</v-icon>View Institution
              Functions</v-btn
            >
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar
                color="rgb(230, 188, 34)"
                dark
                class="justify-center"
                dense
                flat
                >Institution Functions</v-toolbar
              >
              <v-card-text>
                <div class="text-h2 pa-12 col-12">
                  <v-simple-table fixed-header>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="">S/N</th>
                          <th class="text-left">Functions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(inFunction, index) in institutionServices.functions" :key="inFunction.functionUniqueId">
                          <td>{{ index+1 }}</td>
                          <td>{{ inFunction.functionName }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="dialog.value = false">
                  <v-icon color="rgb(230, 188, 34)">mdi-window-close</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </div>
    </v-card>
    <v-card>
      <v-toolbar dark flat color="white">
        <template v-slot:extension>
          <v-tabs
            v-model="tabs"
            centered
            background-color="transparent"
            color="rgb(230, 188, 34)"
            class="font-weight-bold"
            grow
          >
            <v-tab
              v-for="approvedItem in approvedItems"
              :key="approvedItem"
              class="grey--text"
            >
              {{ approvedItem }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <v-card>
            <v-expansion-panels>
              <v-expansion-panel
                v-for="(service, i) in institutionServices.services"
                :key="i"
              >
                <v-expansion-panel-header>
                  {{ service.serviceName }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <template class="text-centre">
                    <v-card-subtitle>
                      <h3 class="text-centre" style="font-weight: bold">
                        1. Service Descriptions
                      </h3>

                      <v-list-item one-line>
                        <v-list-item-content>
                          <v-list-item-subtitle class="text-wrap">{{
                            service.details.detailsDescriptions
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card-subtitle>
                    <v-divider> </v-divider>
                    <v-card-subtitle>
                      <h3 style="font-weight: bold">
                        2.It include other Institutions ?
                      </h3>
                      <v-list-item
                        v-if="service.details.detailsScope == 'Ndio'"
                        class="text-wrap"
                      >
                        <p><v-icon small>mdi-check-decagram</v-icon> Yes</p>
                      </v-list-item>
                      <v-list-item v-else class="text-wrap">
                        <p><v-icon small>mdi-cancel</v-icon> NO</p>
                      </v-list-item>
                    </v-card-subtitle>
                    <v-card-subtitle
                      v-if="service.details.detailsScope == 'Ndio'"
                    >
                      <h3 style="font-weight: bold">
                        Service Co-Provider Institutions
                      </h3>
                      <v-list-item class="text-wrap">
                        <p>
                          <v-icon small>mdi-domain</v-icon>
                          {{ service.details.detailsCoprovider }}
                        </p>
                      </v-list-item>
                    </v-card-subtitle>

                    <v-divider></v-divider>

                    <v-card-subtitle>
                      <h3 style="font-weight: bold">
                        3. Service Establishment Terms
                      </h3>

                      <v-list-item one-line>
                        <v-list-item-content>
                          <v-list-item-subtitle class="text-wrap"
                            >{{ service.details.detailsTermOfService }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card-subtitle>

                    <v-divider></v-divider>

                    <v-card-subtitle>
                      <h3 style="font-weight: bold">4. Service Cost</h3>
                      <span>
                        Total Time Required:
                        <strong>
                          {{ getTotalDays(service.procedure) }} Days</strong
                        ></span
                      >
                      <br />
                      <span>
                        Total Financial Cost:
                        <strong>
                          Tsh {{ getTotalCost(service) }} /=</strong
                        ></span
                      >
                    </v-card-subtitle>

                    <v-card-subtitle>
                      <h3 style="font-weight: bold">5.Service Delivarables</h3>

                      <v-list-item one-line>
                        <v-list-item-content>
                          <v-list-item-subtitle class="text-wrap"
                            >{{ service.details.detailsEndResult }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card-subtitle>
                    <v-divider></v-divider>

                    <v-card-subtitle>
                      <h3 style="font-weight: bold">
                        6. Service Delivery is Automated
                      </h3>
                      <v-list-item
                        v-if="service.details.detailsIsAutomated == 'Ndio'"
                        class="text-wrap"
                      >
                        <p><v-icon small>mdi-check-decagram</v-icon> Yes</p>
                      </v-list-item>
                      <v-list-item v-else class="text-wrap">
                        <p><v-icon small>mdi-cancel</v-icon> NO</p>
                      </v-list-item>
                    </v-card-subtitle>

                    <v-card-subtitle
                      v-if="service.details.detailsIsAutomated == 'Ndio'"
                    >
                      <h3 style="font-weight: bold">System Name</h3>
                      <v-list-item class="text-wrap">
                        <p>
                          <v-icon small>mdi-web</v-icon>
                          {{ service.details.detailsAutomatedSystem }}
                        </p>
                      </v-list-item>
                    </v-card-subtitle>

                    <v-card-subtitle>
                      <h3 style="font-weight: bold">7. Service Requirements</h3>

                      <div class="flex md12 sm12 xs12">
                        <v-list>
                          <v-list-item
                            style="margin: 5px"
                            v-for="(requ, index) in service.requirements"
                            :key="requ.uniqueId"
                          >
                            <v-list-item-section>
                              <v-list-item-label>
                                {{ index + 1 }}.
                                {{ requ.requirementRequirement }}
                              </v-list-item-label>
                            </v-list-item-section>
                          </v-list-item>
                        </v-list>
                      </div>
                    </v-card-subtitle>

                    <v-divider></v-divider>

                    <v-card-subtitle>
                      <h3 style="font-weight: bold">
                        8. Service Delivery Procedures
                      </h3>
                      <div class="va-table-responsive">
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th>No</th>
                                <th>Procedure Name</th>
                                <th>Decision</th>
                                <th>Responsible Personel</th>
                                <th>Time (Days)</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="procedure in service.procedure"
                                :key="procedure"
                              >
                                <td>{{ procedure.procedureNumber }}</td>
                                <td>{{ procedure.procedureName }}</td>
                                <td>{{ procedure.procedureDecision }}</td>
                                <td>
                                  {{ procedure.procedureResponsiblePerson }}
                                </td>
                                <td>{{ procedure.procedureDuration }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </div>
                    </v-card-subtitle>

                    <v-card-subtitle>
                      <h3 style="font-weight: bold">Procedure Diagram</h3>
                      <v-img
                        lazy-src="https://picsum.photos/id/11/10/6"
                        max-height="100%"
                        max-width="100%"
                        :src="service.proccessFlowDiagram"
                      ></v-img>
                    </v-card-subtitle>
                    <v-divider bold class="mb-7"></v-divider>

                    <v-card-subtitle>
                      <h3 style="font-weight: bold">
                        9. Service Delivery Means
                      </h3>
                      <span>{{ service.deliveryMeans }} </span>
                    </v-card-subtitle>
                    <v-divider></v-divider>

                    <v-card-subtitle>
                      <h3 style="font-weight: bold" class="mb-5">
                        Service has Sub Services
                      </h3>
                      <v-list-item
                        v-if="service.hasSubServices == 'Ndio'"
                        class="text-wrap"
                      >
                        <p><v-icon small>mdi-check-decagram</v-icon> Yes</p>
                      </v-list-item>
                      <v-list-item v-else class="text-wrap">
                        <p><v-icon small>mdi-cancel</v-icon> NO</p>
                      </v-list-item>
                      <v-divider bold class="mb-7"></v-divider>
                    </v-card-subtitle>

                    <v-simple-table v-if="service.hasSubServices == 'Ndio'">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th>Sub-Service Name</th>
                            <th>Cost (TSh)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="subservice in service.subServices"
                            :key="subservice"
                          >
                            <td>{{ subservice.name }}</td>
                            <td>{{ subservice.cost }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <v-expansion-panels>
              <v-expansion-panel
                v-for="(item, i) in institutionServices.services"
                :key="i"
              >
                <v-expansion-panel-header>
                  {{ item.name }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <template class="text-centre">
                    <v-card-subtitle>
                      <h3 class="text-centre" style="font-weight: bold">
                        1. BRIEF DESCRIPTION OF THIS SERVICE
                      </h3>

                      <v-list-item one-line>
                        <v-list-item-content>
                          <v-list-item-subtitle class="text-wrap"
                            >This service is provided by this
                            institution</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-card-subtitle>
                    <v-divider> </v-divider>
                    <v-card-subtitle>
                      <h3 style="font-weight: bold">
                        2.DOES THIS SERVICE INCLUDE OTHER INSTITUTIONS?
                      </h3>
                      <v-list-item class="text-wrap">
                        <p><v-icon small>mdi-check-decagram</v-icon> ndio</p>
                        <br />
                      </v-list-item>
                      <v-list-item class="text-wrap">
                        <p><v-icon small>mdi-cancel</v-icon> hapana</p>
                        <br />
                      </v-list-item>
                    </v-card-subtitle>

                    <v-divider></v-divider>

                    <v-card-subtitle>
                      <h3 style="font-weight: bold">
                        3.THE BASIS OF THE EXISTENCE OF THIS SERVICE
                      </h3>

                      <v-list-item one-line>
                        <v-list-item-content>
                          <v-list-item-subtitle class="text-wrap"
                            >Jina la Wakala :
                            <span style="font-weight: bold"> TRA </span
                            ><BR /> Mahali :
                            <span style="font-weight: bold"
                              >Nyerere Square, Sheli ya Gapco
                            </span>
                            <br />
                            Mawasiliano ya Wakala :
                            <span style="font-weight: bold"> +255******* </span
                            ><BR />
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card-subtitle>

                    <v-divider></v-divider>

                    <v-card-subtitle>
                      <h3 style="font-weight: bold">
                        4.RESULTS AFTER SERVICE IS COMPLETED
                      </h3>

                      <v-list-item one-line>
                        <v-list-item-content>
                          <v-list-item-subtitle class="text-wrap"
                            >Muda wa Kutoa Huduma :
                            <span style="font-weight: bold"> SIKU 2 </span
                            ><BR /> Gharama za Huduma :
                            <span style="font-weight: bold">BURE </span>
                            <br />
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card-subtitle>

                    <v-divider></v-divider>

                    <v-card-subtitle>
                      <h3 style="font-weight: bold">
                        4 IS THE PROCESS OF PROVIDING THIS SERVICE PERFORMED BY
                        THE SYSTEM?
                      </h3>

                      <v-list-item one-line>
                        <v-list-item-content>
                          <v-list-item-subtitle class="text-wrap"
                            >Muda wa Kutoa Huduma :
                            <span style="font-weight: bold"> SIKU 2 </span
                            ><BR /> Gharama za Huduma :
                            <span style="font-weight: bold">BURE </span>
                            <br />
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card-subtitle>

                    <v-divider></v-divider>

                    <v-card-subtitle>
                      <h3 style="font-weight: bold">
                        5. IN ORDER FOR A CUSTOMER TO GET THIS SERVICE, WHAT
                        EXAMPLES SHOULD HE HAVE?
                      </h3>
                      <v-list-item one-line>
                        <v-list-item-content>
                          <v-list-item-subtitle class="text-wrap"
                            >Muda wa Kutoa Huduma :
                            <span style="font-weight: bold"> SIKU 2 </span
                            ><BR /> Gharama za Huduma :
                            <span style="font-weight: bold">BURE </span>
                            <br />
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card-subtitle>
                    <v-divider></v-divider>

                    <v-card-subtitle>
                      <h3 style="font-weight: bold">
                        6. IS THE PROCESS OF PROVIDING THIS SERVICE PERFORMED BY
                        THE SYSTEM?
                      </h3>

                      <v-list-item one-line>
                        <v-list-item-content>
                          <v-list-item-subtitle class="text-wrap"
                            >Muda wa Kutoa Huduma :
                            <span style="font-weight: bold"> SIKU 2 </span
                            ><BR /> Gharama za Huduma :
                            <span style="font-weight: bold">BURE </span>
                            <br />
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card-subtitle>

                    <v-divider></v-divider>

                    <v-card-subtitle>
                      <h3 style="font-weight: bold">
                        7. CUSTOMER WAYS TO DELIVER HIS SERVICE AFTER THE
                        PROCESS
                      </h3>

                      <v-list-item one-line>
                        <v-list-item-content>
                          <v-list-item-subtitle class="text-wrap"
                            >Muda wa Kutoa Huduma :
                            <span style="font-weight: bold"> SIKU 2 </span
                            ><BR /> Gharama za Huduma :
                            <span style="font-weight: bold">BURE </span>
                            <br />
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card-subtitle>

                    <v-divider></v-divider>

                    <v-card-subtitle>
                      <h3 style="font-weight: bold">
                        Other Information about Service
                      </h3>
                    </v-card-subtitle>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>



<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    tabs: null,
    approvedItems: ["Approved Serivices", "Unapproved Serivices"],
    desserts: [
      {
        fuction:
          " You are required to include the specified icon library (even if using default). This can be done by including a CDN link or importing the icon library into your application.",
        sn: "01",
      },
      {
        fuction: " Majukumu ya taasisi husika",
        sn: "02",
      },
      {
        fuction: " Majukumu ya taasisi husika",
        sn: "03",
      },
      {
        fuction: " Majukumu ya taasisi husika",
        sn: "04",
      },
      {
        fuction: " Majukumu ya taasisi husika",
        sn: "05",
      },
    ],

    services: [{ name: "Delivery Patents" }, { name: "Provide audit reports" }],
    items: [
      {
        id: 1,
        name: "Applications :",
        children: [
          { id: 2, name: "Calendar : app" },
          { id: 3, name: "Chrome : app" },
          { id: 4, name: "Webstorm : app" },
        ],
      },
      {
        id: 5,
        name: "Documents :",
        children: [
          {
            id: 6,
            name: "vuetify :",
            children: [
              {
                id: 7,
                name: "src :",
                children: [
                  { id: 8, name: "index : ts" },
                  { id: 9, name: "bootstrap : ts" },
                ],
              },
            ],
          },
          {
            id: 10,
            name: "material2 :",
            children: [
              {
                id: 11,
                name: "src :",
                children: [
                  { id: 12, name: "v-btn : ts" },
                  { id: 13, name: "v-card : ts" },
                  { id: 14, name: "v-window : ts" },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 15,
        name: "Downloads :",
        children: [
          { id: 16, name: "October : pdf" },
          { id: 17, name: "November : pdf" },
          { id: 18, name: "Tutorial : html" },
        ],
      },
      {
        id: 19,
        name: "Videos :",
        children: [
          {
            id: 20,
            name: "Tutorials :",
            children: [
              { id: 21, name: "Basic layouts : mp4" },
              { id: 22, name: "Advanced techniques : mp4" },
              { id: 23, name: "All about app : dir" },
            ],
          },
          { id: 24, name: "Intro : mov" },
          { id: 25, name: "Conference introduction : avi" },
        ],
      },
    ],
  }),
  computed: {
    ...mapGetters({
      institutionServices: "getSurveyResponses",
    }),
  },
  methods: {
    getTotalDays(servicProcedures) {
      let tot = 0;
      console.log(servicProcedures);
      servicProcedures.forEach((element) => {
        tot = tot + parseInt(element.procedureDuration);
      });
      return tot;
    },
    getTotalCost(service) {
      let tot = 0;
      if (service.subServices.length > 0) {
        service.subServices.forEach((element) => {
          tot = tot + parseInt(element.cost);
        });
      } else {
        tot = service.generalCost;
      }
      return tot;
    },
  },
  async mounted() {
    await this.$store.dispatch(
      "getInstitutionServicesByUniqueId",
      this.$route.params.institutionUniqueId
    );
  },
};
</script>
