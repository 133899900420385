import Vue from 'vue'
import Vuex from 'vuex'
import AuthStore from './uaa/auth'
import UaaStore from './uaa/uaa'
import RequirementAnalyiticsStore from './analysis/requirements'
import ServiceTypeAnalyticsStore from './analysis/service-type'
import ServiceDeliveryTimeAnalyticsStore from './analysis/service-time'
import ServiceCostAnalyticsStore from './analysis/service-cost'
import ServiceCollaborationAnalyticsStore from './analysis/service-collaboration'
import InstitutionsSurveyResponsesStore from './survey/survey-responses'
import ServiceDeliveryChannelAnalyticsStore from './analysis/service-delivery'
import ServiceAffordabilityAnalyticsStore from './analysis/service-affordability'
import ServiceDashboardStore from './analysis/dashboard'
import createPersistedState from "vuex-persistedstate";



Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    UaaStore,
    AuthStore,
    RequirementAnalyiticsStore,
    ServiceTypeAnalyticsStore,
    ServiceCollaborationAnalyticsStore,
    InstitutionsSurveyResponsesStore,
    ServiceDeliveryTimeAnalyticsStore,
    ServiceCostAnalyticsStore,
    ServiceDeliveryChannelAnalyticsStore,
    ServiceDeliveryTimeAnalyticsStore,
    ServiceAffordabilityAnalyticsStore,
    ServiceDashboardStore
  },
  plugins: [createPersistedState()],
})
