<template>
  <v-container fluid class="my-5" app>
   <v-breadcrumbs :items="items">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
          {{ item.text.toUpperCase() }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
<v-row>
<v-spacer></v-spacer>
      <v-chip
        class="ma-2 mb-7"
        @click="OpenNewRequirmentForm = !OpenNewRequirmentForm"
        v-bind="attrs"
        v-on="on"
        color="primary"
        label
        style="font-weight: bold"
        outlined
        text-color="primary"
      >
        <v-icon left> mdi-account-plus </v-icon>
        New Requirement
      </v-chip>
</v-row>

     <v-card >
          <v-expansion-panels>

          <v-expansion-panel v-for="(item, i) in requirments" :key="i">
            <v-expansion-panel-header>
              {{ item.name }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                    <v-col >

              <template class="text-centre"> 
           
                <v-card-subtitle >
                  <v-list-item  three-line>
                
                        <v-checkbox
              v-for="required in requirmentschecks"
              :key="required.name"
              :label="required.name"
              class="justify-space-between"  
           
              color="rgba(162, 112, 15, 0.81"
            
         
            ></v-checkbox>
                  </v-list-item>
                </v-card-subtitle>
                <v-divider> </v-divider>
                
                <v-divider></v-divider>

              </template>
            </v-col>
            </v-row>

            </v-expansion-panel-content>
          </v-expansion-panel>

          </v-expansion-panels>
        </v-card>
      <AddNewRequirment
        :OpenNewRequirmentForm="OpenNewRequirmentForm"
        @close="
         OpenNewRequirmentForm = !OpenNewRequirmentForm;

        "/>

        <v-dialog
        v-model="popupDialog"
        max-width="600px"
        persistent
        :key="popupDialog"
        >
         <v-card>
        <v-card-title>
          <v-icon>mdi-account-plus-outline</v-icon>
          <span @click="OpenNewRequirmentForm"> Add Requirement </span>
        </v-card-title>
      </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
import AddNewRequirment from "./AddNewRequirment2.vue";
export default {
  components:{
      AddNewRequirment,
  },
  data: () => ({
    OpenNewRequirmentForm:false,
    selectedrequirments:[],
    items: [
       {
        text: "Dashboard",
        disabled: false,
        href: "/private/dashboard",
      },
      {
        text: "List of Requirments",
        disabled: true,
        href: "",
      },
    ],
    requirments:[
      { name: "Hati MbaliMbali" },
      { name: "Vitambulisho vya Vyeti" },
    ],

  requirmentschecks: [
    
   {name:"Mpango mkakati wa Taasisi"},
     { name:  "Majalada husika"},
       {name: "Nishati na Madini"},  
        {name:"Kumbukumbu na nyaraka za Taasisi"},
     { name:  "Kujaza fomu ya usajili wa kikao"},
       {name: "Andiko la mradi"},

      ],
  })

}
</script>

<style>

</style>