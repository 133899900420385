import { apolloClient } from "@/vue-apollo"
import gql from "graphql-tag"

export default {
  state: {
    dodosoResponses: [],
    institutionsSurveyResponses: [],
    responsesDashboard: {}
  },

  mutations: {
    addSurveyResponses(state, data) {
      state.institutionsSurveyResponses = data
    },
    addDodosoResponses(state, data) {
      state.dodosoResponses = data
    },
    addSurveyResponsesDashboard(state, data) {
      state.responsesDashboard = data
    },
    clearSurveyResponses(state) {
      state.institutionsSurveyResponses = {}
    },
  },

  getters: {
    getSurveyResponses(state) {
      return state.institutionsSurveyResponses
    },
    getDodosoResponses(state) {
      return state.dodosoResponses
    },
    getResponsesDashboard(state) {
      return state.responsesDashboard
    },
  },

  actions: {
    async loadSurveyResponses(context) {
      await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: gql`query institutionsSurveyResponses{
                    institutionsSurveyResponses{
                      institutionUniqueId
                      institutionName
                      institutionServices
                      institutionCompletedServices
                      institutionRemainServices
                    }
                  }`
      }).then((response) => {
        context.commit('addDodosoResponses', response.data.institutionsSurveyResponses)
      })
    },
    async loadsurveyResponsesDashboard(context) {
      await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: gql`query surveyResponsesDashboard{
                    surveyResponsesDashboard{
                      institutions
                      approvedServices
                      servicesRequirements
                      servicesCategory
                    }
                  }`
      }).then((response) => {
        context.commit('addSurveyResponsesDashboard', response.data.surveyResponsesDashboard)
      })
    },

    async getInstitutionServicesByUniqueId(context, institutionUniqueId) {
      context.commit('clearSurveyResponses', "")
      apolloClient.query({
        fetchPolicy: 'no-cache',
        query: gql`query loadSurveyResponses($institutionUniqueId:String!){
                loadSurveyResponses(institutionUniqueId:$institutionUniqueId){
                  isApproved
                  hasData
                  functions{
                    functionUniqueId
                    functionName
                  }
                  services{
                    serviceUniqueId
                    serviceName
                    isFullFilled
                    hasSubServices
                    generalCost
                    deliveryMeans
                    procedure{
                      procedureUniqueId
                      procedureNumber
                      procedureName
                      procedureDecision
                      procedureResponsiblePerson
                      procedureCost
                      procedureDuration
                    }
                    proccessFlowDiagram
                    requirements{
                      requirementUniqueId
                      requirementRequirement
                    }
                    methods{
                      deliveryMethod
                    }
                    consumers{
                      automatedUniqueId
                      automatedConsumers
                    }
                    details{
                      detailsDescriptions
                      detailsScope
                      detailsCoprovider
                      detailsType
                      detailsTermOfService
                      detailsHasCompetitor
                      detailsCompetitorName
                      detailsSystemUrl
                      detailsIsAutomated
                      detailsAutomatedSystem
                      detailsEndResult
                    }
                    challenges{
                      uniqueId
                      challenge
                    }
                    recommendations{
                      uniqueId
                      recommendation
                    }
                    subServices{
                      uniqueId
                      name
                      cost
                    }
                  }
                }
              }`,
        variables: {
          institutionUniqueId: institutionUniqueId
        },
      }).then((response) => {
        context.commit('addSurveyResponses', response.data.loadSurveyResponses)
      })
    }
  }
}