<template>
  <v-container fluid class="my-5" app>
    <v-breadcrumbs :items="items">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
          {{ item.text.toUpperCase() }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <v-row>
      <v-col cols="5">
        <div class="text-overline mb-4">
          Percentage of Services grouped based on cost
        </div>
        <v-card>
          <most-used :data="servicesCost"/>
        </v-card>
          <v-card-text>
            <div class="text-overline mb-4">
              Services with high delivery cost per institutions
            </div>
          </v-card-text>
          <v-data-table
            :headers="headers"
            :items="serviceCostAnalytics.mostCostlyServicesPerInstitution"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            class="elevation-5"
          ></v-data-table>
      </v-col>
      <v-col cols="7">
        <div class="text-overline mb-4">
          Top 10 Services with high delivery cost
        </div>
        <v-data-table
          :headers="headerss"
          :items="serviceCostAnalytics.mostCostlyServices"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          class="elevation-1"
        ></v-data-table>
            <div class="text-overline mb-4">
              PERCENTAGE OF SERVICES GROUPED BASED ON COST
            </div>
        <v-card class="mx-auto mb-3 mt-5" max-width="100%" outlined>
          <BarChart :data="series"/>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MostUsed from "./MostUsed.vue";
import BarChart from "./BarChart.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    MostUsed,
    BarChart,
  },
  data: () => ({
    items: [
      {
        text: "Statistics and Reports",
        disabled: false,
        href: "/private/statistics-reports",
      },
      {
        text: "Service Cost Analysis",
        disabled: true,
        href: "",
      },
    ],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    headers: [
      {
        text: "Service Name",
        align: "start",
        sortable: false,
        value: "serviceName",
      },
      { text: "Service Group", value: "serviceInstituion" },
      { text: "Institution Name", value: "serviceGroup" },
      { text: "Accumulative Cost", value: "serviceCost" },
    ],
    headerss: [
      {
        text: "Service Name",
        align: "start",
        sortable: false,
        value: "serviceName",
      },
      { text: "Service Group", value: "serviceInstituion" },
      { text: "Institution Name", value: "serviceGroup" },
      { text: "Accumulative Cost", value: "serviceCost" },
    ],
    services: [
      {
        service: "eGA",
      },
    ],
  }),

  computed:{
    ...mapGetters({
      serviceCostAnalytics: "getServiceCostAnalytics"
    }),

  series() {
      var response = {}

      var labels=[]
      var firts_group_data=[]
      var sec_group_data=[]
      var third_group_data=[]
      var fouths_group_data=[]

      this.serviceCostAnalytics.serviceCostPerGroups.forEach((serviceGroup) => {
        labels.push(serviceGroup.groupName)
        firts_group_data.push(serviceGroup.serviceCostGroups[0].groupValue)        
        sec_group_data.push(serviceGroup.serviceCostGroups[1].groupValue)        
        third_group_data.push(serviceGroup.serviceCostGroups[2].groupValue)        
        fouths_group_data.push(serviceGroup.serviceCostGroups[3].groupValue)        
      });

      response={
        labels:labels,
        firtsData:firts_group_data,
        secondData:sec_group_data,
        thirdData:third_group_data,
        fouthData:fouths_group_data
      }
      return response;
    },

     servicesCost(){
      var data=[]
      this.serviceCostAnalytics.serviceCostGroups.forEach((element) => {
            data.push({ value:element.groupValue, name: element.groupName
 })
    });
      return data
    }
  },


  async mounted() {
      await this.$store.dispatch("loadServiceCostsAnalytics");
    },
};
</script>

<style>
</style>