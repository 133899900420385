<template>
<sequential-entrance>
<v-container fluid>
  <v-layout  class="mt-10">
    <v-flex xs12 sm9 md9 class="mt-10 pa-5">
      <v-card class="elevation-12 mt-5">
        <!-- <v-card   elevation="2"  class="justify-center" > -->
        <v-card-title> KUPATA TIN NAMBA </v-card-title>

        <v-card-subtitle>
          <h3 style="font-weight: bold">1. Kuhusu TIN Namba</h3>

          <v-list-item one-line>
            <v-list-item-content>
              <v-list-item-subtitle class="text-wrap"
                >TIN in namba itolewayo kitaalamu kwa teknolojia ya komputa na
                hivyo kuwa namba ya kipekee isiyoingiliana na namba nyingine.
                Namba hiyo hupewa mlipakodi na kuwa utambulisho wake katika
                shughuli zake zote za kibiashara</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-card-subtitle>
        <v-divider> </v-divider>
        <v-card-subtitle>
          <h3 style="font-weight: bold">2. Mahitaji ili kupata huduma hii</h3>
              <v-list-item class="text-wrap">
                     <p>
              <v-icon small>mdi-check-decagram</v-icon> Picha 2 za pasipoti
              saizi
            </p> <br/>
            </v-list-item>
          <v-list-item class="text-wrap">
            <p>
              <v-icon small>mdi-check-decagram</v-icon> Barua ya utambulisho wa
              makazi (TIN ya biashara)
            </p> <br/>
           
          </v-list-item>
          
          <v-list-item class="text-wrap">
            <p>
              <v-icon small>mdi-check-decagram</v-icon> Kitambulisho cha kudumu
              kama kadi ya kupigia kura, kitambulisho cha uraia, hati ya
              kusafiria (pasipoti) na aina nyingine kama itakavyotakiwa.
            </p>
          </v-list-item>
          <v-list-item class="text-wrap">
          
            <p>
              <v-icon small>mdi-check-decagram</v-icon> Kitambulisho cha kudumu
              kama kadi ya kupigia kura, kitambulisho cha uraia, hati ya
              kusafiria (pasipoti) na aina nyingine kama itakavyotakiwa.
            </p>
          </v-list-item>
        </v-card-subtitle>

        <v-divider></v-divider>

        <v-card-subtitle>
           <h3 style="font-weight: bold">3.Anayetoa Huduma</h3>

          <v-list-item one-line>
            <v-list-item-content>
              <v-list-item-subtitle class="text-wrap"
                >Jina la Wakala : <span style="font-weight: bold"> TRA </span><BR/>
                Mahali :  <span style="font-weight: bold">Nyerere Square, Sheli ya Gapco </span> <br/>
                Mawasiliano ya Wakala :  <span style="font-weight: bold"> +255******* </span><BR/> </v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item> 
        </v-card-subtitle>


        <v-divider></v-divider>

        <v-card-subtitle>
           <h3 style="font-weight: bold">4.Gharama za Huduma</h3>

          <v-list-item one-line>
            <v-list-item-content>
              <v-list-item-subtitle class="text-wrap"
                >Muda wa Kutoa Huduma : <span style="font-weight: bold"> SIKU 2 </span><BR/>
                Gharama za Huduma :  <span style="font-weight: bold">BURE </span> <br/>
                
                 </v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item> 
        </v-card-subtitle>

        <v-btn color="primary" class="ml-5 mb-4">
      Toa Maoni
    </v-btn>
      </v-card>
    </v-flex>

    <v-flex xs12 sm3 md3 class="mt-10">
      <v-card class="elevation-12 mt-5">
        <!-- <v-card   elevation="2"  class="justify-center" > -->
        <v-card-title> Tafuta </v-card-title>

        <!-- <v-card-subtitle>
    
    </v-card-subtitle> -->

        <v-toolbar dark color="#5596e6">
          <!-- <v-toolbar-title>Search</v-toolbar-title> -->
          <v-autocomplete
            v-model="select"
            :loading="loading"
            :items="items"
            :search-input.sync="search"
            cache-items
            class="mx-1"
            flat
            hide-no-data
            hide-details
            label="Huduma Nyinginezo"
            solo-inverted
            outlined
          ></v-autocomplete>
          <!-- <v-btn icon>
      <v-icon>mdi-dots-vertical</v-icon>
    </v-btn> -->
        </v-toolbar>
     <v-list dense>
      <v-subheader>CHAGUA HUDUMA</v-subheader>
      <v-list-item-group
        v-model="selectedItem"
        color="#5596e6"
        :scrollable="scrollable"
      >
        <v-list-item
          v-for="(item, i) in states1"
          :key="i"
        >
          <!-- <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon> -->
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
      </v-card>
    </v-flex>
    </v-layout>
    

</v-container>
</sequential-entrance>
   
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      items: [],
      search: null,
      select: null,
      states1: [
      { text:  "Uchukuzi na Usafirishaji"},
        { text:  "Nishati na Madini"},
       { text:   "Nyumba na huduma za mitaa"},
        { text:  "Ajira, Kazi na Peshen"},
        { text:  "Elimu na Mafunzo"},
        { text:  "Watu wenye ulemavu"},
        { text:  "Uhalifu, haki na sheria"},
         { text: "Uraia, viza na uhamiaji"},
        { text:  "Maji na Umwagiliaji"},
        { text:  "Fedha na ushuru (Kodi)"},
        { text:  "Kilimo na UVuvi"},
        { text:  "Sanaa na Michezo"},
        { text:  "Viwanda na Biashara"},
         { text: "Ulinzi na Usalama"},
         { text: "Mawasiliano na Tehama"},
        { text:  "Nyaraka na takwimu (Data za Wazi)"},
        { text:  "Ardhi na Mazingira"},
        { text:  "Utawala na Uongozi"},
        { text:  "Afya"},
        { text:  "Kuzaliwa, Vifo na Ndoa"},
         { text: "Maliasili na Utalii"},
      ],

        states: [
    "Uchukuzi, Ujenzi na Usafirishaji",
    "Nishati na Madini",
    "Nyumba na huduma za mitaa",
    "Ajira, Kazi na pensheni",
    "Elimu na mafunzo",
    "Watu wenye Ulemavu",
    "Uhalifu, haki na sheria",
    "Uraia, viza na uhamiaji",
    "Maji na Umwagiliaji",
    "Fedha na ushuru (Kodi)",
    "Kilimo na Uvuvi",
    "Sanaa na Michezo",
    "Viwanda na Biashara",
    "Ulinzi na Usalama",
    "Mawasiliano na Tehama",
    "Nyaraka na takwimu (Data za Wazi)",
    "Ardhi na Mazingira",
    "Utawala na Uongozi",
    "Afya",
    "Kuzaliwa, Vifo na Ndoa",
    "Maliasili na Utalii",
  ],

    };
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val);
    },
  },
  methods: {
    querySelections(v) {
      this.loading = true;
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.states.filter((e) => {
          return (e || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1;
        });
        this.loading = false;
      }, 500);
    },
  },
};
</script>

<style>
</style>