<template>
  <v-container fluid class="my-5" app>
    <v-breadcrumbs :items="items">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
          {{ item.text.toUpperCase() }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <v-row>
      <v-col cols="6">       
         <div class="text-overline mb-5">
          Percentage of automated services per sectors
        </div>
        <v-row justify="start" >
            <v-card class="mr-3" max-width="50%">
              <v-card-text>
                <p class="text-h7 text--primary">Number of Automated Services</p>
              <v-list-item-title class="text-h6 mb-1"> {{ServiceDeliveryChannelAnalytics.generalAutomationStatus.autonatedServices}} </v-list-item-title>
              </v-card-text>
            </v-card>
            <v-card class="mr-3" max-width="50%">
              <v-card-text>
                <p class="text-h7 text--primary">Number of Un-Automated Services</p>
              <v-list-item-title class="text-h6 mb-1">{{ServiceDeliveryChannelAnalytics.generalAutomationStatus.unAutonatedServices}}</v-list-item-title>
              </v-card-text>
            </v-card>
        </v-row>

        <div class="text-overline mt-7">
          Percentage of automated services per sectors
        </div>
        <v-card class="mx-auto mb-3 mt-5" max-width="100%" outlined>
          <BarChart :dataLabels="dataLabels" :dataValues="dataValues"/>
        </v-card>
      </v-col>

      <v-col cols="6">    
         <div class="text-overline mb-5">
          Percentage of automated services per services groups
        </div>
        <v-row justify="start" class="mt-3">
          <v-card
            color="rgba(177, 216, 221, 0.336)"
            class="mx-auto ml-1 mt-3"
            max-width="25%"
            outlined
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">G2C</div>
                <v-list-item-subtitle
                  >Percentage of Automated Services
                  </v-list-item-subtitle
                >
                <v-list-item-title class="text-h6 mb-1">
                {{ServiceDeliveryChannelAnalytics.generalServiceTypesAutomationStatus.percentageOfG2c}}%
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-avatar>
                <v-icon color="grey lighten-1">
                  mdi-account-multiple-outline
                </v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </v-card>
          <v-card
            color="rgba(221, 218, 177, 0.336)"
            class="mx-auto ml-1 mt-3"
            max-width="24%"
            outlined
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">G2G</div>
                <v-list-item-subtitle
                  >Percentage of Automated Services</v-list-item-subtitle
                >
                <v-list-item-title class="text-h6 mb-1">
               {{ServiceDeliveryChannelAnalytics.generalServiceTypesAutomationStatus.percentageOfG2g}}%
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-avatar>
                <v-icon color="grey lighten-1">mdi-flag-checkered </v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </v-card>
          <v-card
            color="rgba(221, 177, 212, 0.336)"
            class="mx-auto ml-1 mt-3"
            max-width="24%"
            outlined
          > 
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">G2B</div>
                <v-list-item-subtitle
                  >Percentage of Automated Services
                  </v-list-item-subtitle
                >
                <v-list-item-title class="text-h6 mb-1">
                 {{ServiceDeliveryChannelAnalytics.generalServiceTypesAutomationStatus.percentageOfG2b}}%
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-avatar>
                <v-icon color="grey lighten-1"> mdi-domain </v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </v-card>
          <v-card
            color="rgba(177, 221, 187, 0.336)"
            class="mx-auto ml-1 mt-3"
            max-width="24%"
            outlined
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">G2E</div>
                <v-list-item-subtitle
                  >Percentage of Automated Services
                  </v-list-item-subtitle
                >
                <v-list-item-title class="text-h6 mb-1">
                 {{ServiceDeliveryChannelAnalytics.generalServiceTypesAutomationStatus.percentageOfG2e}}%
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-avatar>
                <v-icon color="grey lighten-1"> mdi-bank-outline </v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </v-card>
        </v-row>
        <div class="text-overline mt-7">
          Automated Services delivering Mechanisms and Automated Services Consumers
        </div>
        <v-row>
          <v-col cols="6">
          <v-card>
              <!-- <v-card-text>
                <p class="text-h6 text--primary">Automated Services delivering Mechanisms</p>
              </v-card-text> -->
              <most-used :data="automatedDeliveringConsumers"/>
            </v-card>
          </v-col>

          <v-col cols="6">
          <v-card>
              <!-- <v-card-text>
                <p class="text-h6 text--primary">Automated Services Consumers</p>
              </v-card-text> -->
              <donut :data="automatedDeliveringConsumers2"/>
            </v-card>
          </v-col>

        </v-row>
      </v-col>
      

        <v-col cols="7">
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import MostUsed from "./MostUsed.vue";
import Donut from "./Donut.vue";
import BarChart from "./BarChart.vue";
import { mapGetters } from "vuex";


export default {
  components: { 
    MostUsed,
    Donut,
    BarChart
  },
  data: () => ({
    items: [
      {
        text: "Statistics and Reports",
        disabled: false,
        href: "/private/statistics-reports",
      },
      {
        text: "Service Delivery Analysis",
        disabled: true,
        href: "",
      },
    ],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    headers: [
      {
        text: "Service",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Involved Partners", value: "calories" },
      { text: "Services Involved", value: "calories" },
      { text: "%", value: "iron" },
      { text: "Action", value: "actions" },
    ],
    dessrets: [

    ],
  }),

  computed:{
    ...mapGetters({
      ServiceDeliveryChannelAnalytics:"getServiceDeliveryChannelAnalytics",
    }),

     dataLabels(){
      var sector=[]
      this.ServiceDeliveryChannelAnalytics.sectorialServiceAutomationStatus.forEach(data => {
          sector.push(data.sectorName)
      });
      return sector
    },

    dataValues(){
      var sector=[]
      this.ServiceDeliveryChannelAnalytics.sectorialServiceAutomationStatus.forEach(data => {
          sector.push(data.percentageOfAutomation)
      });
      return sector
    },
      
    automatedDeliveringConsumers(){
      var data=[]
      this.ServiceDeliveryChannelAnalytics.channelsServiceAutomationStatus.forEach((element) => {
            data.push({ value:element.channelPercentage, name: element.channelName })
    });

      return data

    },   

  
     automatedDeliveringConsumers2(){
      var data2=[]
      this.ServiceDeliveryChannelAnalytics.consumersServiceAutomationStatus.forEach((element) => {
            data2.push({ value:element.consumerPercentage, name: element.consumerName })
    });

      return data2

    },  

    percentageofAutomatedServices(){
      var temp_list = [];
      var temp_list2 = [];
      var temp_list3 = [];
      this.ServiceDeliveryChannelAnalytics.generalServiceTypesAutomationStatus.forEach((service) => {
        temp_list.push(service.percentageOfG2b)
        temp_list2.push(service.percentageOfG2g)
        temp_list3.push(service.percentageOfG2e)
      });
      return (
        temp_list, temp_list2, temp_list3
      )
  
    },
    
    
      },
      
      async mounted() {
      await this.$store.dispatch("loadServiceDeliveryChannelAnalytics");
    },
 
};
</script>

<style>
.p {
  color: rgba(177, 221, 187, 0.336);
}
</style>