<template>
  <div>
    <v-container fluid class="my-5" app>
      <v-breadcrumbs :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs> 
      <v-data-table
        :headers="headers"
        :items="institutionsSurveyResponses"
        class="elevation-1"
      >
        <!-- :custom-filter="filterOnlyCapsText" -->
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Search (UPPER CASE ONLY)"
            class="mx-4"
          ></v-text-field>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            dark
            fab
            x-small
            color="green"
            class="mx-5"   
            link
            :to="'/private/response-Services/' +  item.institutionUniqueId" 
          >
            <v-icon @click="navigateToServices" small>mdi-eye</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>





<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    items: [
      {
        text: "All Dodoso Response",
        disabled: true,
        href: "",
      },
    ],
    dialog: false,
    dialogDelete: false,
    search: "",
    headers: [
      {
        text: "Institution Name",
        align: "start",
        sortable: false,
        value: "institutionName",
      },
      { text: "Total Services", value: "institutionServices" },
      { text: "Completed Services", value: "institutionCompletedServices" },
      { text: "Remaining Services", value: "institutionRemainServices" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    desserts: [],
    
  }),

  
  computed: {
    ...mapGetters({
      institutionsSurveyResponses: "getDodosoResponses",
    }),

    dataLabels() {
      var temp_list = [];
      this.institutionsSurveyResponses.forEach((institution) => {
        temp_list.push(institution.institutionName);
      });
      return temp_list;
    },

    dataValues() {
      var temp_list = [];
      this.institutionsSurveyResponses.forEach((data) => {
        temp_list.push(data.institutionServices);
      });
      return temp_list;
    },
  },

  methods: {
   
    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },

    navigateToServices() {
      this.$router.push("/private/response-Services" + institution.institutionUniqueId);
    },
  },

    async mounted() {
      await this.$store.dispatch("loadSurveyResponses");
    },
};
</script>