<template>
  <div>
       <v-col cols="12">
        <v-row align="center" justify="space-between">
          <v-col
            cols="12"
            sm="4"
            v-for="(feature, i) in Reportcards"
            :key="i"
          >
            <v-hover v-slot:default="{ hover }">
              <v-card link :elevation="hover ? 10 : 4" :class="{ up: hover }" max-width="344" outlined>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">{{feature.title}}</div>
                    <v-list-item-title class="text-h5 mb-1">
                      {{feature.img}}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >{{feature.text}}</v-list-item-subtitle
                    >
                  </v-list-item-content>

                  <v-list-item-avatar
                    tile
                    size="80"
                    color="grey"
                  >
                    <img src="../../../assets/img/building.png" width="45" alt="Logo" />
                  </v-list-item-avatar>
                </v-list-item>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
      <h2 style="color: orange" class="mt-7">{{title}}</h2>

    <v-data-table
      :headers="headers"
      :items="desserts"
      item-key="name"
      class="elevation-1"
      :search="search"
      :custom-filter="filterOnlyCapsText"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search (UPPER CASE ONLY)"
          class="mx-4"
        ></v-text-field>
      </template>
       <template >
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row >
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.name"
                      label="Dessert name"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.calories"
                      label="Calories"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.fat"
                      label="Fat (g)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.carbs"
                      label="Carbs (g)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.protein"
                      label="Protein (g)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
    </template>
    <template v-slot:item.actions="{ item }">
 
      <v-icon
      small 
      color="primary"
      @click="navigateToServices"
      >
        mdi-eye
      </v-icon>
    </template>
    
    </v-data-table>
  </div>
</template>








<script>
  export default {
    data: () => ({
    Reportcards: [
        {
        img: 488,
        title: "Institutions",
        text: "Number of Institution with Public Services",
        },
        {
        img: 156,
        title: "Services",
        text: "Services aoffered by Public Institutions",
        },
        {
        img: 23,
        title: "Categories",
        text: "Service Categories",
        },
    ],
      
      dialog: false,
      dialogDelete: false,
        title:'List of Institutions ',
        search: '',
      headers: [
        {
          text: 'Instituion Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Service Time(Days)', value: 'g2g' },
        { text: 'Cost(TZs)', value: 'cost', sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      defaultItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },
computed: {
      headers () {
        return [
          {
            text: 'Category Name',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          
          { text: 'Total Services', value: 'g2g' },
          
          { text: 'Iron (%)', value: 'iron' },
        ]
      },
    },
    
    methods: {
      initialize () {
        this.desserts = [
          {
            name: 'TRA',
            cost: 1000000,
            g2g: '30',
            
          },
          {
            name: 'TCRA',
            cost: 3000000,
            g2g: '3',
            
          },
          {
            name: 'LATRA',
            cost: 4000000,
            g2g: '1',
            
          },
          {
            name: 'e-GA',
            cost: 2000000,
            g2g: '9',
            
          },
          
          
        ]
      },
       filterOnlyCapsText (value, search, item) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search) !== -1
      },

      viewInstitution (item) {
       console.log(item)
      },

     
      navigateToServices(){
          this.$router.push('/private/responseServices')

      },

    },
  }
</script>