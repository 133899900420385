<template>
  <v-container fluid>
    <div class="row">
      <v-col cols="12">
        <v-row align="center" justify="">
          <v-col cols="12" sm="3" v-for="(feature, i) in Reportcards" :key="i">
            <v-hover v-slot:default="{ hover }">
              <v-col >
              <v-card
                link :to="feature.path"
                :elevation="hover ? 10 : 4"
                :class="{ up: hover }"
              
                outlined
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">{{ feature.title }}</div>
                    <v-list-item-title class="text-h5 mb-1">
                      {{ feature.img }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{
                      feature.text
                    }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-avatar tile size="80" color="grey">
                    <img
                      src="../../../assets/img/building.png"
                      width="45"
                      alt="Logo"
                    />
                  </v-list-item-avatar>
                </v-list-item>
              </v-card>
              </v-col>
            </v-hover>
          </v-col>
        </v-row>
        
      </v-col>

      <v-row>
        <v-col sm="6" cols="6" xs="6" md="6">
          <v-card hover>
            <BarChart :dataLabels="dataLabels" :dataValues="dataValues" />
          </v-card>
        </v-col>

        <v-col sm="6" cols="6" xs="6" md="6">
          <v-card hover>
            <PieChart :data="serviceType"/>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>


<script>
import BarChart from "./BarChart.vue";
import PieChart from "./PieChart.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    BarChart,
    PieChart,
  },

  data() {
    return {
    };

    
  },
  computed: {
    ...mapGetters({
      RequirementsAnalytics: "getDashboardAnalytics",
      ServiceTypeAnalytics: "getServiceTypeDashboardAnalytics",
      ResponsesDashboard:"getResponsesDashboard",
      institutionsSurveyResponses:"getInstitutionListAnalytics"
    }),

      dataLabels(){
        var requirement=[]
        this.RequirementsAnalytics.topFiveUsage.forEach(data => {
            requirement.push(data.requirementUsage)
        });
        return requirement
      },

      dataValues(){
        var requirement=[]
        this.RequirementsAnalytics.topFiveUsage.forEach(data => {
            requirement.push(data.requirementName)
        });
        return requirement
      },

      serviceType(){
        var data=[]
        this.ServiceTypeAnalytics.servicePerCategory.forEach((usage) => {
              data.push({ value:usage.value, name: usage.name })
        });
        return data
      },

      institutionList(){
        return this.$store.state.ServiceDashboardStore.institutionListAnalytics.length;
      },
       institutionService(){
        return this.$store.state.ServiceDashboardStore.institutionListAnalytics;
      },

      Reportcards(){
          return [
            {
              img: this.institutionList,
              title: "Institutions",
              text: "Number of Institution with Public Services",
              path:'institution-service-list'
            },
            {
              img: this.ResponsesDashboard.approvedServices,
              title: "Services",
              text: "Services Offered by Public Institutions",
              path:'list-of-services-Dashboard'
            },
            {
              img: this.ResponsesDashboard.servicesCategory,
              title: "Categories",
              text: "Service Categories",
              path:'list-of-category'
            },
            {
              img: this.ResponsesDashboard.servicesRequirements,
              title: "Requirments",
              text: "Important requirments required on services",
              path: 'list-of-requirments',
            },
          ]
      },

    },

    async mounted() {
    await this.$store.dispatch("loadDashboardAnalytics");
    await this.$store.dispatch("loadServiceTypesAnalytics");
    await this.$store.dispatch("loadsurveyResponsesDashboard");
    await this.$store.dispatch("loadInstitutionList");
    
    
  },

};
</script>
